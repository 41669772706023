import { FacilityInfo } from '../my-agreements/types'

export interface Offer {
  name: string
  description: string
  clientRef: string
  price: number
  timeUnit: string
  durations: number[]
}

export interface PermitConfig {
  facility: FacilityInfo
  offers: Offer[]
  title: string
  parentLink: string
}

export interface PermitConfigState {
  permitConfig: PermitConfig | null
}

export const GET_PERMIT_CONFIG_REQUEST = 'GET_PERMIT_CONFIG_REQUEST'
export const GET_PERMIT_CONFIG_SUCCESS = 'GET_PERMIT_CONFIG_SUCCESS'

interface getPermitConfigRequestAction {
  type: typeof GET_PERMIT_CONFIG_REQUEST
}

interface getPermitConfigSuccessAction {
  type: typeof GET_PERMIT_CONFIG_SUCCESS
  payload: {
    data: PermitConfig
  }
}

export type PermitConfigActionTypes = getPermitConfigRequestAction | getPermitConfigSuccessAction
