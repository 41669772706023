import { datadogRum } from '@datadog/browser-rum'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { doLogOut } from '../../pages/auth/auth'
import { AppThunk } from '../../store'
import { getAllNotificationFromIndexedDb } from '../../store/app-notification/thunk'
import { getConsumerFullProfileSuccess, setFirebaseInitialized } from '../../store/auth/actions'
import { currentUser } from '../../store/auth/reducers'
import { getConsumerFullProfile } from '../../store/auth/thunks'
import {
  resendMobileVerificationLinkForConsumer,
  resendVerificationEmailForConsumer,
} from '../../store/email-verification/thunks'
import { setNotification } from '../../store/notifications/action'
import { NotificationType } from '../../store/notifications/types'
import { getConsumerOrderedFacilities } from '../../store/ordered-facilities/thunks'
import { UserClass } from '../../store/parking-quote/types'
import { ROUTE_PATH_SIGN_IN } from '../constants'

const useConsumerProfile = () => {
  const dispatch = useDispatch()
  const location = window.location

  const user = useSelector(currentUser)

  useEffect(() => {
    const resendConsumerEmail = async (emailType: string, userEmail: string, userId: string) => {
      if (emailType === 'mobile') {
        const resendMobileLinkForConsumerSuccess: AppThunk<Promise<boolean>> = await dispatch(
          resendMobileVerificationLinkForConsumer(userEmail, userId),
        )
        dispatch(getConsumerFullProfileSuccess(null))
        if (Boolean(resendMobileLinkForConsumerSuccess)) {
          dispatch(
            setNotification(NotificationType.ERROR, 'Account verification is incomplete. Please check your email.'),
          )
        }
      } else {
        const resendEmailForConsumerSuccess: AppThunk<Promise<boolean>> = await dispatch(
          resendVerificationEmailForConsumer(userEmail, userId),
        )
        dispatch(getConsumerFullProfileSuccess(null))
        if (Boolean(resendEmailForConsumerSuccess)) {
          dispatch(
            setNotification(NotificationType.ERROR, 'Account verification is incomplete. Please check your email.'),
          )
        }
      }
    }
    if (user) {
      if (user.userClass === UserClass.Consumer) {
        if (user.enabled && user.emailVerified && user.mobileVerified) {
          if (location.pathname !== ROUTE_PATH_SIGN_IN) {
            datadogRum.setUserProperty('id', user.id)
            dispatch(getConsumerFullProfile(user.id))
          }
          dispatch(getAllNotificationFromIndexedDb())
          dispatch(getConsumerOrderedFacilities(user.id))
        } else if (location.pathname !== ROUTE_PATH_SIGN_IN) {
          if (!user.emailVerified) {
            resendConsumerEmail('email', user.email, user.id)
          } else {
            resendConsumerEmail('mobile', user.email, user.id)
          }
        }
      } else {
        doLogOut(dispatch)
        dispatch(setFirebaseInitialized())
        dispatch(setNotification(NotificationType.ERROR, 'User not authenticated'))
      }
    }
    // eslint-disable-next-line
  }, [user, dispatch])
}

export default useConsumerProfile
