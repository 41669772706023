export interface FirebaseConfig {
  apiKey: string
  authDomain: string
  projectId: string
  appId: string
  messagingSenderId: string
}

export interface FirebaseAuthConfig {
  tenantId: string
}

export interface FirebaseMessagingConfig {
  vapidKey: string
}

export const PARK_API_CONFIG = 'park'

export interface MapBoxSearchBounds {
  restrict: boolean
  useParkerLocation: boolean
  minLat: number | null
  minLng: number | null
  maxLat: number | null
  maxLng: number | null
  postCodes: string[]
}

export interface MapBoxSearch {
  url: string | null
  bounds: MapBoxSearchBounds
}

export interface MapBoxConfig {
  search: MapBoxSearch
  token: string
  url: string
}

export interface TenantParkApiConfig {
  type: typeof PARK_API_CONFIG
  apiPath: string
  jurisdictionCode: string
  operatorId: string
  operatorName: string
  infraCdnUrl: string
  environment: string
  desktopHostName: string
  mobileHostName: string
  paymentApiPath: string
  platformStatus: PlatformStatus
  homeLayout: FeatureType[]
  supportedFeatureTypes: FeatureType[]
}

export interface TenantConfigTenant {
  id: string
  code: string
  apiKey: string
  tenancyCode: string
}

export interface DatadogConfig {
  applicationId: string
  clientToken: string
}

export interface TagManager {
  tagManagerId: string | null
}

export interface TenantConfig {
  config: TenantParkApiConfig
  datadog: DatadogConfig | null
  firebase: FirebaseConfig
  firebaseAuth: FirebaseAuthConfig
  firebaseMessaging: FirebaseMessagingConfig
  mapBox: MapBoxConfig
  tagManager?: TagManager
  tenant: TenantConfigTenant
}

export interface AccessCredential {
  accessMode: AccessMode
  value: string
}

export enum PlatformStatus {
  Active = 'Active',
  Offline = 'Offline',
}

export enum AccessMode {
  LPR = 'LPR',
  CreditCard = 'CreditCard',
  Barcode = 'Barcode',
  QR = 'QR',
  BLE = 'BLE',
  NFC = 'NFC',
  Attended = 'Attended',
  AssistedPulse = 'AssistedPulse',
  DirectPulse = 'DirectPulse',
  Cellular = 'Cellular',
  ReverseQR = 'ReverseQR',
}

export enum EntryStatus {
  OffSite = 'OffSite',
  OnSite = 'OnSite',
  TransitIn = 'TransitIn',
  TransitOut = 'TransitOut',
  Suspect = 'Suspect',
  Fallback = 'Fallback',
}

export enum OrderStatus {
  Pending = 'Pending',
  Paid = 'Paid',
  Declined = 'Declined',
  Free = 'Free',
  Cancelled = 'Cancelled',
  Amended = 'Amended',
  PreAuth = 'PreAuth',
}

export enum GeoFacilityType {
  PrimaryCarPark = 'PrimaryCarPark',
  VirtualCarPark = 'VirtualCarPark',
  NestedCarPark = 'NestedCarPark',
  CarParkTenancy = 'CarParkTenancy',
  FacilityZone = 'FacilityZone',
}

export enum ParkingLineItemStayAgreementType {
  Primary = 'Primary',
  Guest = 'Guest',
}

export enum AgreementType {
  Single = 'Single',
  Weekly = 'Weekly',
  Permanent = 'Permanent',
  Daily = 'Daily',
  Charge = 'Charge',
  Now = 'Now',
}

export enum HeaderVariant {
  Full = 'Full',
  Park = 'Park',
  FullHome = 'FullHome',
  AccessCarpark = 'AccessCarpark',
}

export enum BayReservedType {
  Reserved = 'Reserved',
  Unreserved = 'Unreserved',
  Access = 'Access',
}

export enum DayOfWeek {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export interface DayOfWeekKeyValuePair {
  key: DayOfWeek
  value: string
}

export enum AgreementAuthorityType {
  SalesChannelRes = 'SalesChannelRes',
  SalesChannelPark = 'SalesChannelPark',
  SalesChannelLease = 'SalesChannelLease',
  SalesChannelPay = 'SalesChannelPay',
  SalesChannelCharge = 'SalesChannelCharge',
  SalesChannelValido = 'SalesChannelValido',
  SalesChannelPass = 'SalesChannelPass',
  SalesChannelUnknown = 'SalesChannelUnknown',
  EntitlementAssignment = 'EntitlementAssignment',
  EntitlementGuest = 'EntitlementGuest',
  EntitlementFleet = 'EntitlementFleet',
  Hosted = 'Hosted',
  ConsumerGuest = 'ConsumerGuest',
  SalesChannelAccess = 'SalesChannelAccess',
  SalesChannelCpms = 'SalesChannelCpms',
}

export enum LatestMovementAccessType {
  Entry = 'Entry',
  Exit = 'Exit',
  NestedEntry = 'NestedEntry',
  NestedExit = 'NestedExit',
}

export enum LatestMovementResult {
  ValidationFailed = 'ValidationFailed',
  Confirmed = 'Confirmed',
  Suspect = 'Suspect',
}

export enum FooterItem {
  MyAccount = 'MyAccount',
  ActiveParks = 'ActiveParks',
}

export enum FeatureType {
  SalesChannelAccess = 'SalesChannelAccess',
  SalesChannelRes = 'SalesChannelRes',
  SalesChannelCharge = 'SalesChannelCharge',
  SalesChannelLease = 'SalesChannelLease',
  SalesChannelPark = 'SalesChannelPark',
  SalesChannelPay = 'SalesChannelPay',
  SalesChannelValido = 'SalesChannelValido',
  Enter = 'Enter',
  Feedback = 'Feedback',
  FeedbackLink = 'FeedbackLink',
  Help = 'Help',
  Manage = 'Manage',
  QuickPark = 'QuickPark',
  Search = 'Search',
  DownloadApp = 'DownloadApp',
}

export interface IdName {
  id: string
  name: string
}

export enum UserAgentType {
  PWA = 'PWA',
  MobileBrowser = 'MobileBrowser',
  DesktopBrowser = 'DesktopBrowser',
  App = 'App',
  API = 'API',
}

export const SESSION_STORAGE_KEY_BASKET_ID = 'nuevopark.basket.id'
export const SESSION_STORAGE_KEY_PARKING_QUOTE_ID = 'nuevopark.parking.quote.id'
