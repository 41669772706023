export const SERVER_API_TIMEOUT = 30000
export const SERVER_API_KEY_HEADER = 'x-api-key'
export const AUTHORIZATION_HEADER = 'Authorization'
export const BEARER_PREFIX = 'Bearer '
export const PARK_API_KEY_HEADER = 'x-api-key'
export const BASKET_ID_HEADER = 'Basket-Id'
export const BASE_DISTRIBUTOR_MEMBER_URL = '/operators/:operatorId/distributors/:distributorId/members/:memberId'

export interface ErrorResponse {
  code: string
  message: string
}

export interface ErrorResponseData {
  errors: ErrorResponse[]
}
