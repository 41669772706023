export enum BrandingElementName {
  HeaderLogo = 'HeaderLogo',
  HeaderColour = 'HeaderColour',
  HeaderTextColour = 'HeaderTextColour',
  ButtonColour = 'ButtonColour',
  ButtonTextColour = 'ButtonTextColour',
  TileLogo = 'TileLogo',
  TileBackgroundColour = 'TileBackgroundColour',
  TileIconColour = 'TileIconColour',
  TileTextColour = 'TileTextColour',
  AccentColour = 'AccentColour',
  LinkColour = 'LinkColour',
  FormIconColour = 'FormIconColour',
  SplashBackImage = 'SplashBackImage',
  OfferTileLogo = 'OfferTileLogo',
  NotificationTileLogo = 'NotificationTileLogo',
  AddOnsTileLogo = 'AddOnsTileLogo',
  HeaderAppHomeLogo = 'HeaderAppHomeLogo',
  HeaderAppMainFlowLogo = 'HeaderAppMainFlowLogo',
  AppWhiteFormIcon = 'AppWhiteFormIcon',
  AccessQRCodeIcon = 'AccessQRCodeIcon',
  CarParkTileIcon = 'CarParkTileIcon',
  AppHomeBackgroundColour = 'AppHomeBackgroundColour',
  AccessTileIcon = 'AccessTileIcon',
  BookTileIcon = 'BookTileIcon',
  ParkTileIcon = 'ParkTileIcon',
  FeedbackTileIcon = 'FeedbackTileIcon',
  ManageTileIcon = 'ManageTileIcon',
  QuickParkTileIcon = 'QuickParkTileIcon',
  SearchTileIcon = 'SearchTileIcon',
  SupportTileIcon = 'SupportTileIcon',
  ValidateTileIcon = 'ValidateTileIcon',
  ParkLocationId1Icon = 'ParkLocationId1Icon',
}

export enum BrandingIconType {
  Icon192 = 'Icon192',
  Icon384 = 'Icon384',
  Icon512 = 'Icon512',
  Icon1024 = 'Icon1024',
  IconMaskable192 = 'IconMaskable192',
  IconAppleTouch192 = 'IconAppleTouch192',
  IconFav64 = 'IconFav64',
}

export enum ElementType {
  Colour = 'Colour',
  Image = 'Image',
}

export interface ImageValue {
  mimeType: string
  url: string
}

export interface ColourValue {
  value: string
}

export interface BrandingElement {
  name: BrandingElementName
  elementType: ElementType
  imageValue?: ImageValue
  colourValue?: ColourValue
}

export interface BrandingIcon {
  id: string
  type: BrandingIconType
  mimeType: string
  imageValue: {
    id: string
    url: string
    contentType: string
    fileName: string
  }
  version: number
}

export interface Branding {
  id: string
  name: string
  version: number
  operatorId: string
  operatorName: string
  elements: BrandingElement[]
  icons: BrandingIcon[]
}

export interface BrandingState {
  branding: Branding | null
  isRequestCompleted: boolean
}

export const GET_BRANDING_API_RESOURCE_PATH = '/public/operators/:operatorId/branding'

export const GET_BRANDING_REQUEST = 'GET_BRANDING_REQUEST'
export const GET_BRANDING_SUCCESS = 'GET_BRANDING_SUCCESS'

interface getBrandingRequestAction {
  type: typeof GET_BRANDING_REQUEST
}

interface getBrandingSuccessAction {
  type: typeof GET_BRANDING_SUCCESS
  payload: {
    data: Branding | null
  }
}

export type BrandingActionTypes = getBrandingRequestAction | getBrandingSuccessAction
