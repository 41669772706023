import {
  FleetEntitlementsActionTypes,
  FleetEntitlementsState,
  GET_FLEET_ENTITLEMENT_DETAILS_SUCCESS,
  GET_FLEET_ENTITLEMENT_VEHICLES_REQUEST,
  GET_FLEET_ENTITLEMENT_VEHICLES_SUCCESS,
  GET_FLEET_ENTITLEMENTS_REQUEST,
  GET_FLEET_ENTITLEMENTS_SUCCESS,
  RESET_FLEET_ENTITLEMENT_DETAILS,
  RESET_FLEET_ENTITLEMENTS,
} from './types'

const initialState: FleetEntitlementsState = {
  fleetEntitlements: [],
  fleetEntitlementsLoading: false,
  fleetEntitlementsSuccess: false,
  fleetEntitlementsRequestFinished: false,
  fleetEntitlement: null,
  fleetEntitlementDetailsRequestFinished: false,
  fleetEntitlementVehicles: [],
  fleetEntitlementVehiclesRequestFinished: false,
}

export function fleetEntitlementsReducer(
  state: FleetEntitlementsState = initialState,
  action: FleetEntitlementsActionTypes,
): FleetEntitlementsState {
  switch (action.type) {
    case GET_FLEET_ENTITLEMENTS_REQUEST:
      return {
        ...state,
        fleetEntitlements: [],
        fleetEntitlementsLoading: true,
        fleetEntitlementsSuccess: false,
        fleetEntitlementsRequestFinished: false,
      }
    case GET_FLEET_ENTITLEMENTS_SUCCESS:
      return {
        ...state,
        fleetEntitlementsLoading: false,
        fleetEntitlementsSuccess: true,
        fleetEntitlements: action.payload.data,
        fleetEntitlementsRequestFinished: true,
      }
    case GET_FLEET_ENTITLEMENT_DETAILS_SUCCESS:
      return {
        ...state,
        fleetEntitlement: action.payload.data,
        fleetEntitlementDetailsRequestFinished: true,
      }
    case GET_FLEET_ENTITLEMENT_VEHICLES_REQUEST:
      return {
        ...state,
        fleetEntitlementVehicles: [],
        fleetEntitlementVehiclesRequestFinished: false,
      }
    case GET_FLEET_ENTITLEMENT_VEHICLES_SUCCESS:
      return {
        ...state,
        fleetEntitlementVehicles: action.payload.data,
        fleetEntitlementVehiclesRequestFinished: true,
      }
    case RESET_FLEET_ENTITLEMENTS:
      return {
        ...state,
        fleetEntitlements: [],
        fleetEntitlementsLoading: false,
        fleetEntitlementsSuccess: false,
        fleetEntitlementsRequestFinished: false,
      }
    case RESET_FLEET_ENTITLEMENT_DETAILS:
      return {
        ...state,
        fleetEntitlement: null,
        fleetEntitlementDetailsRequestFinished: false,
      }
    default:
      return state
  }
}
