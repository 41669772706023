import axios, { AxiosRequestConfig } from 'axios'
import { buildAxiosConfig, findConfiguration } from '../../common/utility'
import { ErrorResponse } from '../../config/axios/types'
import { AccessControlType, LocationClass } from '../carpark-long-info/types'
import { AppThunk } from '../index'
import { setLoading } from '../loading/reducers'
import { setNotification } from '../notifications/action'
import { NotificationType } from '../notifications/types'
import { getCarParkRequest, getCarParkSuccess } from './actions'
import { ShortInfoPromiseResponse } from './types'

export const CAR_PARK_SHORT_INFO_BY_PUBLIC_ID_API_RESOURCE_PATH =
  '/public/operators/:operatorId/facilities-by-public-id/:id/short-info'
export const CAR_PARK_SHORT_INFO_ZONES_BY_PUBLIC_ID_API_RESOURCE_PATH =
  '/public/operators/:operatorId/facilities-zones-by-public-id/:id/short-info'
export const CAR_PARK_SHORT_INFO_BY_CLIENT_REF_API_RESOURCE_PATH =
  '/public/operators/:operatorId/facilities-by-client-ref/:clientRef/short-info'
export const GET_CAR_PARK_LOCATION_BY_PUBLIC_ID_API_RESOURCE_PATH =
  '/public/operators/:operatorId/locations-by-public-id/:publicId'

export const getShortInfoFromPublicId =
  (publicId: string): AppThunk<Promise<ShortInfoPromiseResponse>> =>
  async (dispatch, getState) => {
    let shortInfoPromiseResponse: ShortInfoPromiseResponse = {
      success: false,
      publicId: '',
      facilityId: '',
      accessControlType: AccessControlType.Enforced,
      locationClass: LocationClass.OnStreet,
      timeZoneId: '',
    }
    dispatch(setLoading(true))
    dispatch(getCarParkRequest())
    const { operatorId } = findConfiguration(getState())
    const config: AxiosRequestConfig = await buildAxiosConfig(getState())
    await axios
      .get(
        CAR_PARK_SHORT_INFO_BY_PUBLIC_ID_API_RESOURCE_PATH.replace(':operatorId', operatorId).replace(
          ':id',
          publicId.toUpperCase(),
        ),
        config,
      )
      .then((response) => {
        dispatch(getCarParkSuccess(response.data))
        shortInfoPromiseResponse = {
          success: true,
          publicId: response.data.publicId,
          facilityId: response.data.id,
          accessControlType: response.data.accessControlType,
          locationClass: response.data.locationClass,
          timeZoneId: response.data.timeZoneId,
        }
        dispatch(setLoading(false))
      })
      .catch((error: ErrorResponse | string) => {
        if (typeof error === 'object') {
          if (error.code === '400') {
            dispatch(setNotification(NotificationType.ERROR, 'Invalid Location ID.'))
          } else {
            dispatch(setNotification(NotificationType.ERROR, error.message))
          }
        }
        dispatch(setLoading(false))
      })
    return shortInfoPromiseResponse
  }

export const getShortInfoZonesFromPublicId =
  (publicId: string, params?: any): AppThunk<Promise<ShortInfoPromiseResponse>> =>
  async (dispatch, getState) => {
    let shortInfoPromiseResponse: ShortInfoPromiseResponse = {
      success: false,
      publicId: '',
      facilityId: '',
      accessControlType: AccessControlType.Enforced,
      locationClass: LocationClass.OnStreet,
      timeZoneId: '',
    }
    dispatch(setLoading(true))
    dispatch(getCarParkRequest())
    const { operatorId } = findConfiguration(getState())
    const config: AxiosRequestConfig = await buildAxiosConfig(getState())
    config.params = params
    await axios
      .get(
        CAR_PARK_SHORT_INFO_ZONES_BY_PUBLIC_ID_API_RESOURCE_PATH.replace(':operatorId', operatorId).replace(
          ':id',
          publicId.toUpperCase(),
        ),
        config,
      )
      .then((response) => {
        dispatch(getCarParkSuccess(response.data))
        shortInfoPromiseResponse = {
          success: true,
          publicId: response.data.publicId,
          facilityId: response.data.id,
          accessControlType: response.data.accessControlType,
          locationClass: response.data.locationClass,
          timeZoneId: response.data.timeZoneId,
        }
        dispatch(setLoading(false))
      })
      .catch((error: ErrorResponse | string) => {
        if (typeof error === 'object') {
          if (error.code === '400') {
            dispatch(setNotification(NotificationType.ERROR, 'Invalid Location ID.'))
          } else {
            dispatch(setNotification(NotificationType.ERROR, error.message))
          }
        }
        dispatch(setLoading(false))
      })
    return shortInfoPromiseResponse
  }

export const getShortInfoFromClientRef =
  (clientRef: string, parkParachute?: boolean): AppThunk<Promise<ShortInfoPromiseResponse>> =>
  async (dispatch, getState) => {
    let shortInfoPromiseResponse: ShortInfoPromiseResponse = {
      success: false,
      publicId: '',
      facilityId: '',
      accessControlType: AccessControlType.Enforced,
      locationClass: LocationClass.OnStreet,
      timeZoneId: '',
    }
    dispatch(setLoading(true))
    dispatch(getCarParkRequest())
    const { operatorId } = findConfiguration(getState())
    const config: AxiosRequestConfig = await buildAxiosConfig(getState())
    await axios
      .get(
        CAR_PARK_SHORT_INFO_BY_CLIENT_REF_API_RESOURCE_PATH.replace(':operatorId', operatorId).replace(
          ':clientRef',
          clientRef.toUpperCase(),
        ),
        config,
      )
      .then((response) => {
        dispatch(getCarParkSuccess(response.data))
        shortInfoPromiseResponse = {
          success: true,
          publicId: response.data.publicId,
          facilityId: response.data.id,
          accessControlType: response.data.accessControlType,
          locationClass: response.data.locationClass,
          timeZoneId: response.data.timeZoneId,
        }
        dispatch(setLoading(false))
      })
      .catch((error: ErrorResponse | string) => {
        if (typeof error === 'object') {
          if (error.code === '400') {
            if (parkParachute) {
              shortInfoPromiseResponse.errorNotFound = true
            } else {
              dispatch(setNotification(NotificationType.ERROR, 'Invalid Location ID.'))
            }
          } else {
            dispatch(setNotification(NotificationType.ERROR, error.message))
          }
        }
        dispatch(setLoading(false))
      })
    return shortInfoPromiseResponse
  }

export const getLocationByPublicId =
  (publicId: string): AppThunk<Promise<boolean>> =>
  async (dispatch, getState) => {
    let getLocationSuccess: boolean = false
    const config = await buildAxiosConfig(getState())
    const { operatorId } = findConfiguration(getState())
    await axios
      .get(
        GET_CAR_PARK_LOCATION_BY_PUBLIC_ID_API_RESOURCE_PATH.replace(':operatorId', operatorId).replace(
          ':publicId',
          publicId,
        ),
        config,
      )
      .then((_response) => {
        getLocationSuccess = true
      })
      .catch((error: ErrorResponse | string) => {
        if (typeof error === 'object') {
          if (error.code === '400') {
            dispatch(setNotification(NotificationType.ERROR, 'Invalid Location ID.'))
          } else {
            dispatch(setNotification(NotificationType.ERROR, error.message))
          }
        }
      })
    return getLocationSuccess
  }
