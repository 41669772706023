import { getAuth, signOut } from 'firebase/auth'
import { Dispatch } from 'react'
import { CLEAR_STORE } from '../../store/auth/types'

export function doLogOut(dispatch: Dispatch<any>) {
  const auth = getAuth()
  signOut(auth)
    .then(() => {
      console.info('sign out existing user')
    })
    .catch((error) => {
      console.error('error when signing out existing user ' + JSON.stringify(error))
    })
  dispatch({ type: CLEAR_STORE })
}
