import { User as FireBaseUser } from 'firebase/auth'
import { AccessCredential } from '../../common/types'
import { BASE_DISTRIBUTOR_MEMBER_URL } from '../../config/axios/types'
import { ConsumerAccessCredential } from '../access-credentials/types'
import { ChargeAccDetails } from '../charge-acc/types'
import { ConsumerCards } from '../consumer-cards/types'
import { ConsumerVehicle } from '../consumer-vehicle/types'
import { ConsumerFavouriteCarPark } from '../my-favourite-carparks/types'
import { ConsumerType, Mobile } from '../park-and-pay/types'
import { UserClass } from '../parking-quote/types'

export enum SessionState {
  EmailVerification = 'EmailVerification',
  MobileVerification = 'MobileVerification',
  PasswordReset = 'PasswordReset',
  Completed = 'Completed',
}

export enum SessionStatus {
  Queued = 'Queued',
  Success = 'Success',
}

export enum SignupSource {
  Access = 'Access',
  Charge = 'Charge',
}

export enum UserSessionSource {
  Access = 'Access',
  Charge = 'Charge',
}

export enum EmailStatus {
  SignUp = 'SignUp',
  SignIn = 'SignIn',
  VerifyEmail = 'VerifyEmail',
  VerifyMobile = 'VerifyMobile',
  ResetPassword = 'ResetPassword',
}

export enum OrganisationType {
  Operator = 'Operator',
  CarParkTenant = 'CarParkTenant',
  Distributor = 'Distributor',
  Landlord = 'Landlord',
}

export interface ConsumerSaveRequest {
  acceptedTermsAndPrivacy: boolean
  sendPromoAndOffers: boolean
  firstName: string
  lastName: string
  email: string
  mobile: Mobile
  providerId: string
  timeZoneId: string
  operatorId?: string
  linkOrderId?: string
  signupSource?: SignupSource
  consumerType?: ConsumerType
}

export interface ConsumerSaveResponse {
  id: string
  sessionId: string
  sessionState: SessionState
  sessionStatus: SessionStatus
  sessionType: string
}

export interface AuthState {
  user: User | null // WhoAmI
  firebaseUser: FireBaseUser | null // Firebase
  consumerSaveResponse: ConsumerSaveResponse | null
  consumer: Consumer | null // Full-Profile
  settings: Settings | null
  favouritesCarParks: ConsumerFavouriteCarPark[] | null
  vehicles: ConsumerVehicle[] | null
  paymentCards: ConsumerCards[] | null
  accessCredentials: AccessCredential[]
  firebaseInitialized: boolean
  isWhoAmIRequestCompleted: boolean
  isConsumerRequestCompleted: boolean
  chargeAccount?: ChargeAccDetails
}

export interface User {
  id: string
  email: string
  providerId: string
  userClass: UserClass
  enabled: boolean
  emailVerified: boolean
  mobileVerified: boolean
  tenantId: string
  username: string
  timeZoneId: string
  organisationType: OrganisationType
  organisationId: string
}

export interface Consumer {
  id: string
  email: string
  mobile: Mobile
  operatorId: string
  providerId?: string
  firstName: string
  lastName: string
  consumerType: ConsumerType
  sendPromoAndOffers?: boolean
  acceptedTermsAndPrivacy?: boolean
  timeZoneId?: string
  verificationSessionId?: string
  linkOrderId?: string
  signupSource?: SignupSource
}

export interface ConsumerFullProfile {
  id: string
  email: string
  mobile: Mobile
  operatorId: string
  firstName: string
  lastName: string
  consumerType: ConsumerType
  settings: Settings | null
  favouritesCarParks: ConsumerFavouriteCarPark[] | null
  vehicles: ConsumerVehicle[] | null
  paymentCards: ConsumerCards[] | null
  sendPromoAndOffers?: boolean
  timeZoneId: string
  accessCredentials: AccessCredential[]
  chargeAccount?: ChargeAccDetails
}

export interface Settings {
  appNotificationsEnabled: boolean
}

export const CREATE_SIGNED_UP_CONSUMER_RESOURCE_PATH = '/public/operators/:operatorId/consumers'
export const CHECK_CONSUMER_STATUS = '/public/operators/:operatorId/consumers:checkStatus'
export const FETCH_WHOAMI_RESOURCE_PATH = '/tenants/:tenantId/users/whoami'
export const GET_CONSUMER_FULL_PROFILE_RESOURCE_PATH = '/operators/:operatorId/consumers/:consumerId/full-profile'
export const GET_DISTRIBUTOR_MEMBER_FULL_PROFILE_RESOURCE_PATH = `${BASE_DISTRIBUTOR_MEMBER_URL}/full-profile`
export const UPDATE_CONSUMER_LIFECYCLE_API_RESOURCE_PATH = '/operators/:operatorId/consumers/:consumerId/lifecycle'
export const UPDATE_DISTRIBUTOR_MEMBER_LIFECYCLE_API_RESOURCE_PATH = `${BASE_DISTRIBUTOR_MEMBER_URL}/lifecycle`

export const SET_LOGGED_ON = 'SET_LOGGED_ON'
export const SET_LOGGED_OFF = 'SET_LOGGED_OFF'

export const SAVE_CONSUMER_REQUEST = 'SAVE_CONSUMER_REQUEST'
export const SAVE_CONSUMER_SUCCESS = 'SAVE_CONSUMER_SUCCESS'

export const WHOAMI_REQUEST = 'WHOAMI_REQUEST'
export const WHOAMI_SUCCESS = 'WHOAMI_SUCCESS'

export const GET_CONSUMER_FULL_PROFILE_REQUEST = 'GET_CONSUMER_FULL_PROFILE_REQUEST'
export const GET_CONSUMER_FULL_PROFILE_SUCCESS = 'GET_CONSUMER_FULL_PROFILE_SUCCESS'

export const GET_CONSUMER_CARDS_SUCCESS = 'GET_CONSUMER_CARDS_SUCCESS'
export const GET_CONSUMER_FAVOURITE_CARPARKS_SUCCESS = 'GET_CONSUMER_FAVOURITE_CARPARKS_SUCCESS'
export const GET_CONSUMER_VEHICLE_SUCCESS = 'GET_CONSUMER_VEHICLE_SUCCESS'

export const GET_CHARGE_ACCOUNT_DETAILS_REQUEST = 'GET_CHARGE_ACCOUNT_DETAILS_REQUEST'
export const GET_CHARGE_ACCOUNT_DETAILS_SUCCESS = 'GET_CHARGE_ACCOUNT_DETAILS_SUCCESS'

export const UPDATE_CONSUMER_SUCCESS = 'UPDATE_CONSUMER_SUCCESS'
export const UPDATE_CONSUMER_NOTIFICATIONS_ENABLED = 'UPDATE_CONSUMER_NOTIFICATIONS_ENABLED'
export const UPDATE_CONSUMER_ACCESS_CREDENTIALS = 'UPDATE_CONSUMER_ACCESS_CREDENTIALS'
export const SET_FIREBASE_INITIALIZED = 'SET_FIREBASE_INITIALIZED'

export const CLEAR_STORE = 'CLEAR_STORE'

interface setLoggedOnAction {
  type: typeof SET_LOGGED_ON
  payload: {
    data: FireBaseUser
  }
}

interface setLoggedOffAction {
  type: typeof SET_LOGGED_OFF
}

interface whoamiRequest {
  type: typeof WHOAMI_REQUEST
}

interface whoamiSuccess {
  type: typeof WHOAMI_SUCCESS
  payload: {
    data: User | null
  }
}

interface saveConsumerRequest {
  type: typeof SAVE_CONSUMER_REQUEST
}

interface saveConsumerSuccess {
  type: typeof SAVE_CONSUMER_SUCCESS
  payload: {
    data: ConsumerSaveResponse
  }
}

interface getConsumerFullProfileRequest {
  type: typeof GET_CONSUMER_FULL_PROFILE_REQUEST
}

interface getConsumerFullProfileSuccess {
  type: typeof GET_CONSUMER_FULL_PROFILE_SUCCESS
  payload: {
    data: ConsumerFullProfile | null
  }
}

interface getConsumerCardsSuccessAction {
  type: typeof GET_CONSUMER_CARDS_SUCCESS
  payload: {
    cards: ConsumerCards[]
  }
}

interface getConsumerFavouriteCarParksSuccessAction {
  type: typeof GET_CONSUMER_FAVOURITE_CARPARKS_SUCCESS
  payload: {
    favouriteCarParks: ConsumerFavouriteCarPark[]
  }
}

interface getConsumerVehicleSuccessAction {
  type: typeof GET_CONSUMER_VEHICLE_SUCCESS
  payload: {
    vehicles: ConsumerVehicle[]
  }
}

export interface getChargeAccDetailsRequest {
  type: typeof GET_CHARGE_ACCOUNT_DETAILS_REQUEST
}

export interface getChargeAccDetailsSuccess {
  type: typeof GET_CHARGE_ACCOUNT_DETAILS_SUCCESS
  payload: {
    data: ChargeAccDetails
  }
}

interface updateConsumerSuccessAction {
  type: typeof UPDATE_CONSUMER_SUCCESS
  payload: {
    data: Consumer
  }
}

interface updateConsumerNotificationsEnabledAction {
  type: typeof UPDATE_CONSUMER_NOTIFICATIONS_ENABLED
  payload: {
    settings: Settings
  }
}

interface updateConsumerAccessCredentialsAction {
  type: typeof UPDATE_CONSUMER_ACCESS_CREDENTIALS
  payload: {
    data: ConsumerAccessCredential[]
  }
}

interface setFirebaseInitialized {
  type: typeof SET_FIREBASE_INITIALIZED
}

export type AuthActionTypes =
  | setLoggedOnAction
  | setLoggedOffAction
  | saveConsumerRequest
  | saveConsumerSuccess
  | whoamiRequest
  | whoamiSuccess
  | getConsumerFullProfileRequest
  | getConsumerFullProfileSuccess
  | getConsumerCardsSuccessAction
  | getConsumerFavouriteCarParksSuccessAction
  | getConsumerVehicleSuccessAction
  | getChargeAccDetailsRequest
  | getChargeAccDetailsSuccess
  | updateConsumerSuccessAction
  | updateConsumerNotificationsEnabledAction
  | updateConsumerAccessCredentialsAction
  | setFirebaseInitialized
