import { BASE_DISTRIBUTOR_MEMBER_URL } from '../../config/axios/types'
import { SessionState, SessionStatus, UserSessionSource } from '../auth/types'

export interface EmailVerificationRequest {
  email: string
}

export interface EmailVerificationResponse {
  id: string
  sessionId: string
  sessionSource?: UserSessionSource
  sessionState: SessionState
  sessionStatus: SessionStatus
  sessionType: string
}

export interface Session {
  organisationType: string
  organisationId: string
  sessionId: string
  sessionType: string
  sessionState: SessionState
  sessionStatus: SessionStatus
}

export interface MobileVerificationResponse {
  id: string
  sessionId: string
  sessionSource?: UserSessionSource
  sessionType: string
  sessionState: SessionState
  sessionStatus: SessionStatus
}

export interface EmailVerificationState {
  session: Session | null
  emailVerificationResponse: EmailVerificationResponse | null
  mobileVerificationResponse: MobileVerificationResponse | null
}

export const UPDATE_EMAIL_VERIFICATION_API_RESOURCE_PATH =
  '/public/operators/:operatorId/sessions/:sessionId:updateEmailVerified'
export const GET_SESSION_BY_ID_API_RESOURCE_PATH = '/public/operators/:operatorId/sessions/:sessionId'
export const RESEND_VERIFICATION_EMAIL_API_RESOURCE_PATH =
  '/public/operators/:operatorId/sessions/:sessionId:resendVerificationEmail'
export const UPDATE_MOBILE_VERIFICATION_FOR_SIGN_UP_API_RESOURCE_PATH =
  '/public/operators/:operatorId/sessions/:sessionId:updateMobileVerified'
export const RESEND_MOBILE_VERIFICATION_CODE_API_RESOURCE_PATH =
  '/public/operators/:operatorId/sessions/:sessionId:resendMobileVerificationSms'
export const RESEND_VERIFICATION_EMAIL_FOR_CONSUMER_API_RESOURCE_PATH =
  '/operators/:operatorId/consumers/:consumerId:resendVerificationEmail'
export const RESEND_VERIFICATION_EMAIL_FOR_DISTRIBUTOR_API_RESOURCE_PATH = `${BASE_DISTRIBUTOR_MEMBER_URL}:resendVerificationEmail`
export const RESEND_MOBILE_VERIFICATION_LINK_FOR_CONSUMER_API_RESOURCE_PATH =
  '/operators/:operatorId/consumers/:consumerId:resendMobileVerificationLink'
export const RESEND_MOBILE_VERIFICATION_LINK_FOR_DISTRIBUTOR_API_RESOURCE_PATH = `${BASE_DISTRIBUTOR_MEMBER_URL}:resendMobileVerificationLink`
export const RESEND_PUBLIC_VERIFICATION_EMAIL_FOR_CONSUMER_API_RESOURCE_PATH =
  '/public/operators/:operatorId/consumers:resendVerificationEmail'
export const RESEND_PUBLIC_MOBILE_VERIFICATION_LINK_FOR_CONSUMER_API_RESOURCE_PATH =
  '/public/operators/:operatorId/consumers:resendMobileVerificationLink'
export const GET_MOBILE_VERIFICATION_CODE_FOR_CONSUMER_API_RESOURCE_PATH =
  'public/operators/:operatorId/sessions/:sessionId:checkSendMobileVerificationSms'

export const RESEND_EMAIL_VERIFICATION_REQUEST = 'RESEND_EMAIL_VERIFICATION_REQUEST'
export const RESEND_EMAIL_VERIFICATION_SUCCESS = 'RESEND_EMAIL_VERIFICATION_SUCCESS'

export const GET_SESSION_BY_ID_REQUEST = 'GET_SESSION_BY_ID_REQUEST'
export const GET_SESSION_BY_ID_SUCCESS = 'GET_SESSION_BY_ID_SUCCESS'

export const UPDATE_EMAIL_VERIFICATION_REQUEST = 'UPDATE_EMAIL_VERIFICATION_REQUEST'
export const UPDATE_EMAIL_VERIFICATION_SUCCESS = 'UPDATE_EMAIL_VERIFICATION_SUCCESS'

export const RESEND_MOBILE_VERIFICATION_CODE_REQUEST = 'RESEND_MOBILE_VERIFICATION_CODE_REQUEST'
export const RESEND_MOBILE_VERIFICATION_CODE_SUCCESS = 'RESEND_MOBILE_VERIFICATION_CODE_SUCCESS'

export const UPDATE_MOBILE_VERIFICATION_FOR_SIGN_UP_REQUEST = 'UPDATE_MOBILE_VERIFICATION_FOR_SIGN_UP_REQUEST'
export const UPDATE_MOBILE_VERIFICATION_FOR_SIGN_UP_SUCCESS = 'UPDATE_MOBILE_VERIFICATION_FOR_SIGN_UP_SUCCESS'

export const RESEND_EMAIL_VERIFICATION_FOR_CONSUMER_REQUEST = 'RESEND_EMAIL_VERIFICATION_FOR_CONSUMER_REQUEST'
export const RESEND_EMAIL_VERIFICATION_FOR_CONSUMER_SUCCESS = 'RESEND_EMAIL_VERIFICATION_FOR_CONSUMER_SUCCESS'

export const RESEND_MOBILE_VERIFICATION_LINK_FOR_CONSUMER_REQUEST =
  'RESEND_MOBILE_VERIFICATION_LINK_FOR_CONSUMER_REQUEST'
export const RESEND_MOBILE_VERIFICATION_LINK_FOR_CONSUMER_SUCCESS =
  'RESEND_MOBILE_VERIFICATION_LINK_FOR_CONSUMER_SUCCESS'

export const GET_MOBILE_VERIFICATION_CODE_FOR_CONSUMER_REQUEST = 'GET_MOBILE_VERIFICATION_CODE_FOR_CONSUMER_REQUEST'
export const GET_MOBILE_VERIFICATION_CODE_FOR_CONSUMER_SUCCESS = 'GET_MOBILE_VERIFICATION_CODE_FOR_CONSUMER_SUCCESS'

export interface resendEmailVerificationRequest {
  type: typeof RESEND_EMAIL_VERIFICATION_REQUEST
}

export interface resendEmailVerificationSuccess {
  type: typeof RESEND_EMAIL_VERIFICATION_SUCCESS
  payload: {
    data: EmailVerificationResponse
  }
}

export interface getSessionByIdRequest {
  type: typeof GET_SESSION_BY_ID_REQUEST
}

export interface getSessionByIdSuccess {
  type: typeof GET_SESSION_BY_ID_SUCCESS
  payload: {
    data: Session
  }
}

export interface updateEmailVerificationRequest {
  type: typeof UPDATE_EMAIL_VERIFICATION_REQUEST
}

export interface updateEmailVerificationSuccess {
  type: typeof UPDATE_EMAIL_VERIFICATION_SUCCESS
  payload: {
    data: EmailVerificationResponse
  }
}

export interface resendMobileVerificationCodeRequest {
  type: typeof RESEND_MOBILE_VERIFICATION_CODE_REQUEST
}

export interface resendMobileVerificationCodeSuccess {
  type: typeof RESEND_MOBILE_VERIFICATION_CODE_SUCCESS
  payload: {
    data: MobileVerificationResponse
  }
}

export interface updateMobileVerificationForSignUpRequest {
  type: typeof UPDATE_MOBILE_VERIFICATION_FOR_SIGN_UP_REQUEST
}

export interface updateMobileVerificationForSignUpSuccess {
  type: typeof UPDATE_MOBILE_VERIFICATION_FOR_SIGN_UP_SUCCESS
  payload: {
    data: MobileVerificationResponse
  }
}

export interface resendEmailVerificationForConsumerRequest {
  type: typeof RESEND_EMAIL_VERIFICATION_FOR_CONSUMER_REQUEST
}

export interface resendEmailVerificationForConsumerSuccess {
  type: typeof RESEND_EMAIL_VERIFICATION_FOR_CONSUMER_SUCCESS
  payload: {
    data: EmailVerificationResponse
  }
}

export interface resendMobileVerificationLinkForConsumerRequest {
  type: typeof RESEND_MOBILE_VERIFICATION_LINK_FOR_CONSUMER_REQUEST
}

export interface resendMobileVerificationLinkForConsumerSuccess {
  type: typeof RESEND_MOBILE_VERIFICATION_LINK_FOR_CONSUMER_SUCCESS
  payload: {
    data: MobileVerificationResponse
  }
}

export interface getMobileVerificationCodeForConsumerRequest {
  type: typeof GET_MOBILE_VERIFICATION_CODE_FOR_CONSUMER_REQUEST
}

export interface getMobileVerificationCodeForConsumerSuccess {
  type: typeof GET_MOBILE_VERIFICATION_CODE_FOR_CONSUMER_SUCCESS
  payload: {
    data: MobileVerificationResponse
  }
}

export type EmailVerificationActionTypes =
  | resendEmailVerificationRequest
  | resendEmailVerificationSuccess
  | getSessionByIdRequest
  | getSessionByIdSuccess
  | updateEmailVerificationRequest
  | updateEmailVerificationSuccess
  | resendMobileVerificationCodeRequest
  | resendMobileVerificationCodeSuccess
  | updateMobileVerificationForSignUpRequest
  | updateMobileVerificationForSignUpSuccess
  | resendEmailVerificationForConsumerRequest
  | resendEmailVerificationForConsumerSuccess
  | resendMobileVerificationLinkForConsumerRequest
  | resendMobileVerificationLinkForConsumerSuccess
  | getMobileVerificationCodeForConsumerRequest
  | getMobileVerificationCodeForConsumerSuccess
