import { Action, AnyAction, applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware, { ThunkAction } from 'redux-thunk'
import { accessCredentialsReducer } from './access-credentials/reducer'
import { facilityAccessInfoReducer } from './access-info/reducers'
import { accessPointReducer } from './access-points/reducers'
import { amenityReducer } from './amenity/reducers'
import { appNotificationReducer } from './app-notification/reducers'
import { appConfigReducer } from './app/reducers'
import { forgotPasswordReducer } from './auth/forgot-password/reducer'
import { authReducer } from './auth/reducers'
import { CLEAR_STORE } from './auth/types'
import { basketReducer } from './basket/reducers'
import { brandingReducer } from './branding/reducers'
import { cancelBookingReducer } from './cancel-booking/reducer'
import { carParkFacilitiesReducer } from './carpark-facilities/reducer'
import { carparkLongInfoReducer } from './carpark-long-info/reducers'
import { carParkSearchReducer } from './carpark-search/reducers'
import { consumerPresenceReducer } from './consumer-presence/reducers'
import { consumerTransactionsReducer } from './consumer-transactions/reducer'
import { consumerVehicleReducer } from './consumer-vehicle/reducers'
import { emailVerificationReducer } from './email-verification/reducer'
import { entitlementDetailsReducer } from './entitlement-details/reducers'
import { entitlementVehicleReducer } from './entitlement-vehicle/reducers'
import { entitlementsReducer } from './entitlements/reducers'
import { facilitiesByOfferReducer } from './facilities-by-offer/reducer'
import { feedbackReducer } from './feedback/reducer'
import { guestAgreementsReducer } from './find-booking/reducers'
import { fleetEntitlementsReducer } from './fleet-entitlements/reducer'
import { geoFeaturesReducer } from './geo-feature/address-or-landmark/reducers'
import { geoFeatureFacilitiesReducer } from './geo-feature/facilities/reducer'
import { geoFeatureProximityReducer } from './geo-feature/proximity/reducers'
import { territoriesReducer } from './geo-feature/territories/reducers'
import { guestAgreementsListReducer } from './guest-agreements/reducer'
import { loadingReducer } from './loading/reducers'
import { mapBoxGeoFeaturesReducer } from './mapbox-geo-feature/reducers'
import { myAgreementsReducer, orderAgreementReducer } from './my-agreements/reducers'
import { consumerFavouriteCarParksReducer } from './my-favourite-carparks/reducers'
import { notificationsReducer } from './notifications/reducer'
import { operatingHoursReducer } from './operating-hours/reducer'
import { orderedFacilitiesReducer } from './ordered-facilities/reducer'
import { orderFormReducer } from './park-and-pay/reducer'
import { parkFlowReducer } from './park/reducer'
import { quoteReducer } from './parking-quote/reducers'
import { parkingQuotesAndExitTimesReducer } from './parking-quotes-exit-times/reducer'
import { permitConfigReducer } from './permit-config/reducer'
import { pwaInstallReducer } from './pwa-install/reducers'
import { recentParksReducer } from './recent-parks/reducer'
import { urlConfigReducer } from './url-config/reducer'
import { vehicleMakesReducer } from './vehicle-makes/reducers'

const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25,
})

export const appReducer = combineReducers({
  authReducer,
  appConfigReducer,
  carParkSearchReducer,
  geoFeaturesReducer,
  mapBoxGeoFeaturesReducer,
  geoFeatureProximityReducer,
  geoFeatureFacilitiesReducer,
  territoriesReducer,
  vehicleMakesReducer,
  consumerVehicleReducer,
  quoteReducer,
  basketReducer,
  orderFormReducer,
  amenityReducer,
  loadingReducer,
  emailVerificationReducer,
  parkingQuotesAndExitTimesReducer,
  notificationsReducer,
  accessPointReducer,
  facilityAccessInfoReducer,
  consumerFavouriteCarParksReducer,
  myAgreementsReducer,
  orderAgreementReducer,
  consumerPresenceReducer,
  appNotificationReducer,
  guestAgreementsReducer,
  entitlementsReducer,
  entitlementDetailsReducer,
  entitlementVehicleReducer,
  carparkLongInfoReducer,
  brandingReducer,
  forgotPasswordReducer,
  cancelBookingReducer,
  orderedFacilitiesReducer,
  carParkFacilitiesReducer,
  urlConfigReducer,
  parkFlowReducer,
  operatingHoursReducer,
  recentParksReducer,
  guestAgreementsListReducer,
  consumerTransactionsReducer,
  pwaInstallReducer,
  feedbackReducer,
  accessCredentialsReducer,
  permitConfigReducer,
  facilitiesByOfferReducer,
  fleetEntitlementsReducer,
})

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === CLEAR_STORE) {
    const appConfigState = state.appConfigReducer
    const permitConfigState = state.permitConfigReducer
    const brandingState = state.brandingReducer
    const notificationsState = state.notificationsReducer
    const territoriesState = state.territoriesReducer
    const urlConfigState = state.urlConfigReducer
    const vehicleMakesState = state.vehicleMakesReducer
    state = {
      appConfigReducer: appConfigState,
      permitConfigReducer: permitConfigState,
      brandingReducer: brandingState,
      notificationsReducer: notificationsState,
      territoriesReducer: territoriesState,
      urlConfigReducer: urlConfigState,
      vehicleMakesReducer: vehicleMakesState,
    }
  }
  return appReducer(state, action)
}

export default function configureStore(preloadedState: RootState | undefined) {
  const store = createStore(rootReducer, preloadedState, composeEnhancers(applyMiddleware(thunkMiddleware)))
  return store
}

export type RootState = ReturnType<typeof rootReducer>
export type AppThunk<R> = ThunkAction<R, RootState, null, Action>
