import { BASE_DISTRIBUTOR_MEMBER_URL } from '../../config/axios/types'
import { SalesChannelType } from '../park-and-pay/types'
import {
  FromPrice,
  Info,
  OfferDto,
  PurchaseType,
  QuoteWarning,
  RelatedAgreement,
  Revision,
  StayAgreementDto,
  SubscriptionDto,
  SubscriptionFeeType,
  Terms,
} from '../parking-quote/types'

export const GET_BASKET_API_RESOURCE_PATH = '/public/operators/:operatorId/baskets/mybasket'
export const GET_DISTRIBUTOR_BASKET_API_RESOURCE_PATH = `${BASE_DISTRIBUTOR_MEMBER_URL}/baskets/mybasket`
export const DELETE_PAYMENT_FROM_BASKET_API_RESOURCE_PATH =
  '/public/operators/:operatorId/baskets/mybasket/financial-transaction-methods/payment'
export const DELETE_DISTRIBUTOR_PAYMENT_FROM_BASKET_API_RESOURCE_PATH = `${BASE_DISTRIBUTOR_MEMBER_URL}/baskets/mybasket/financial-transaction-methods/payment`
export const GET_BASKET_CONFIGURED_CHARGES_API_RESOURCE_PATH =
  '/public/operators/:operatorId/baskets/mybasket/configured-charges'
export const PUT_CONVENIENCE_NOTIFICATIONS_TO_BASKET_API_RESOURCE_PATH =
  '/public/operators/:operatorId/baskets/mybasket/parking-items/:basketItemId/convenience-notifications'
export const PUT_DISTRIBUTOR_CONVENIENCE_NOTIFICATIONS_TO_BASKET_API_RESOURCE_PATH = `${BASE_DISTRIBUTOR_MEMBER_URL}/baskets/mybasket/parking-items/:basketItemId/convenience-notifications`

export const SET_PARKING_BASKET_REQUEST = 'SET_PARKING_BASKET_REQUEST'
export const SET_PARKING_BASKET_SUCCESS = 'SET_PARKING_BASKET_SUCCESS'
export const GET_CONFIGURED_CHARGES_REQUEST = 'GET_CONFIGURED_CHARGES_REQUEST'
export const GET_CONFIGURED_CHARGES_SUCCESS = 'GET_CONFIGURED_CHARGES_SUCCESS'
export const SET_PARKING_BASKET_EXPIRED = 'SET_PARKING_BASKET_EXPIRED'
export const RESET_BASKET_CONTACT = 'RESET_BASKET_CONTACT'
export const RESET_BASKET = 'RESET_BASKET'

export interface Basket {
  id: string
  kind: 'Basket'
  selfLink?: string
  operatorId: string
  parkingItem: ParkingItemResponseDto
  subscriptionItem: SubscriptionItemDto
  retailProducts?: RetailProductItemDto[]
  salesChannelType: SalesChannelType
  subTotalIncTaxes: number
  paymentAmount: number
  subTotalExPaymentCharges?: number
  paymentProcessingCharges: CalculatedServiceCharge[]
  paymentTransactionMethod?: FinancialTransactionMethod
  calculatedServiceCharges: CalculatedServiceCharge[]
  calculatedInclusiveTaxes?: CalculatedInclusiveTaxItem[]
  calculatedLevies: CalculatedLevy[]
  contact?: Contact
  creditAmount: number
  promoCode?: string
  warnings?: QuoteWarning[]
}

export interface SubscriptionItemDto {
  basketItemId: string
  subscriptionId: string
  name: string
  summary: string
  imageUrl?: string
  subscriptionFeeList: SubscriptionItemFeeDto[]
  accountTopUpList: SubscriptionItemTopUpDto[]
}

export interface RetailProductItemDto {
  basketItemId: string
  quoteId: string
  quoteItemId: string
  productId: string
  supplierId: string
  virtualCarParkIds: string[]
  name: string
  summary: string
  price: number
  retailProductCategory: RetailProductCategory
  imageUrl: string
  variants?: RetailProductItemVariantDto[]
  marketing: Info
  maxStockPerTransaction: number
  catalogueInfo: CatalogueInfo
}

export interface CatalogueInfo {
  label: string
  labelValue: string
}

export interface RetailProductItemDetails {
  basketItemId: string
  productId: string
  supplierId: string
  name: string
  summary: string
  productCharge: number
  imageUrl?: string
  retailProductCategory: RetailProductCategory
  variants?: RetailProductItemDetailVariant[]
  configuredRecoveryCharges: LevyChargeConfigured[]
  configuredServiceCharges: LevyChargeConfigured[]
  configuredLevies: LevyChargeConfigured[]
}

export interface SubscriptionItemFeeDto {
  id: string
  name: string
  subscriptionFeeType: SubscriptionFeeType
  amount: number
}

export interface SubscriptionItemTopUpDto {
  id: string
  topUpType: TopUpType
  name: string
  amount: number
}

export enum TopUpType {
  Initial = 'Initial',
}

export enum RetailProductCategory {
  Service = 'Service',
  Promo = 'Promo',
}

export interface RetailProductItemRequest {
  quoteId: string | null | undefined
  quoteItemId: string | null
  retailProductId: string | null
  supplierId: string | null
  variants: RetailProductItemVariantRequest[]
  quantity?: number
}

export interface RetailProductItemVariantRequest {
  retailProductVariantId: string
  quantity: number | null
}

export interface RetailProductItemVariantDto {
  retailProductVariantId: string
  imageUrl: string
  name: string
  summary: string
  sortOrder: number
  price: string
  unitPrice: number
  unitRetail: number
  quantity: number
  maxStockPerTransaction: number
  catalogueInfo: CatalogueInfo
}

export interface RetailProductItemDetailVariant {
  retailProductVariantId: string
  imageUrl?: string
  name: string
  summary: string
  sortOrder: number
  price: number
  retail: number
  quantity: number
  variantCharge?: number
}

export interface CalculatedServiceCharge {
  id: string
  name: string
  note: string
  amount: number
}

export interface PaymentProcessingCharge {
  id: string
  name: string
  note: string
  amount: number
}

export interface CalculatedInclusiveTaxItem {
  revision: Revision
  name: string
  note: string
  amount: number
}

export interface CalculatedLevy {
  id: string
  name: string
  note: string
  amount: number
}

export enum PaymentChannel {
  PayPal = 'PayPal',
  GooglePay = 'GooglePay',
  ApplePay = 'ApplePay',
  CreditCard = 'CreditCard',
}

export interface CreditCardDto {
  creditCardType: CreditCardType
  maskedNumber: string | null
  expiryMonth: number
  expiryYear: number
  cardToken: string | null
  tokenizedCardId?: string | null
}
export enum CreditCardType {
  Amex = 'Amex',
  Mastercard = 'Mastercard',
  Visa = 'Visa',
}

export interface ParkingItemResponseDto {
  basketItemId: string
  parkingQuoteId: string | null | undefined
  parkingQuoteItemId: string
  offer: OfferDto
  subscription: SubscriptionDto
  stayAgreements: StayAgreementDto[]
  allowTimeExtend: boolean
  maxStayOperationHours: number
  purchaseType: PurchaseType
  convenienceNotifications: ConvenienceNotificationDto[]
  fulfilmentNotifications: FulfilmentNotificationDto[]
  vehicle: Vehicle
  offerFromPrice: FromPrice
  relatedParkingItem?: RelatedAgreement
}

export interface FinancialTransactionMethod {
  paymentChannel: PaymentChannel
  creditCardMethod: CreditCardDto
}

export interface FinancialTransactionMethodTokenize {
  paymentChannel: PaymentChannel
  creditCardMethod: TokenizedCardIdOnly
}

export interface TokenizedCardIdOnly {
  tokenizedCardId: string | null
}

export interface Vehicle {
  makeCode: string
  plate: string
  consumerVehicleId?: string
  plateIssuer: string
}

export interface Contact {
  firstName: string
  lastName: string
  email: string | null
  mobile: Mobile | null
  consumerId?: string | null
}

export interface Mobile {
  number: string
  country: string
}

export interface ConvenienceNotificationDto {
  id: string
  selected: boolean
  name: string
  communicationType: CommunicationType
  eventTrigger: EventTrigger
  eventType: EventType
  minutes: null
  price?: null
  marketing: Info
  terms: Terms
}

export interface FulfilmentNotificationDto {
  id: string
  selected: boolean
  name: string
  communicationType: CommunicationType
  eventTrigger: EventTrigger
  eventType: EventType
  minutes: null
  price?: null
  marketing: Info
  terms: Terms
}

export interface BasketItemIdOnly {
  basketId: string
  basketItemId: string
}

export interface BasketNotification {
  notificationId: string
  selected: boolean
}

export interface ParkingBasketRequest {
  kind: 'Basket'
  salesChannelType: SalesChannelType
  parkingItem: ParkingItemRequest
  replacesBasketId?: string
}

export interface ParkingItemRequest {
  parkingQuoteId: string | null
  parkingQuoteItemId: string | null
  quantity?: number
}

export interface InclusiveTax {
  revision: Revision
  name: string
  invoiceNote: string
  percentage: number
  fraction: number
}

export interface LevyChargeConfigured {
  revision: Revision
  name: string
  invoiceNote: string
  amountType: AmountType
  fixed?: number
  percentage?: number
  chargeType: ChargeType
  taxable: boolean
  recoveryType?: string
}

export enum EventTrigger {
  Before = 'Before',
  On = 'On',
  After = 'After',
}

export enum EventType {
  Order = 'Order',
  Reload = 'Reload',
  ReloadFail = 'ReloadFail',
  Start = 'Start',
  End = 'End,',
  Amend = 'Amend',
  Cancel = 'Cancel',
  Extend = 'Extend,',
}

export enum CommunicationType {
  Email = 'Email',
  SMS = 'SMS',
  Push = 'Push',
}

export enum ChargeType {
  Statutory = 'Statutory',
  Service = 'Service',
  Recovery = 'Recovery',
}

export enum AmountType {
  FixedPerOrder = 'FixedPerOrder',
  FixedPerLine = 'FixedPerLine',
  FixedPerVariant = 'FixedPerVariant',
  FixedPerUnit = 'FixedPerUnit',
  Percentage = 'Percentage',
}

export interface IdOnly {
  id: string
}

export interface RetailProductSupplementaryInfoUnits {
  basketId: string
  basketItemId: string
  retailProductId: string
  productUnits?: RetailProductSupplementaryUnitDto[]
  variantUnits?: RetailProductSupplementaryUnitDto[]
}

export interface RetailProductSupplementaryUnitDto {
  variantId: string
  unitNo: string
  fields: SupplementaryInfoField[]
}

export interface SupplementaryInfoField {
  supplementaryInfoId: string
  required: boolean
  value: SupplementaryInfoFieldValue
  valid: boolean
}

export interface SupplementaryInfoUnit {
  unitNo: number
  fields: SupplementaryInfoField[]
}

export interface SupplementaryInfoFieldValue {
  stringValue: string | null
  integerValue: number | null
  dateValue: string | null
  timeValue: string | null
  dateTimeValue: string | null
  timeValueDate: Date | null
  addressValue?: SupplementaryInfoAddress | null
}

export interface RetailProductSupplementaryInfo {
  supplementaryInfoId: string | null | undefined
  retailProductVariantId: string
  unitNumber: number
  stringValue?: string | null
  integerValue?: number | null
  dateValue?: string | null
  timeValue?: string | null
  timeValueDate?: Date | null
  dateTimeValue?: string | null
  addressValue?: SupplementaryInfoAddress | null
  supplementaryInfo?: SupplementaryInfo | undefined
}

export interface SupplementaryInfo {
  id: string
  name: string
  required: boolean | false
  validationType: ValidationType | null
}

export interface SupplementaryInfoAddress {
  line1: string
  line2?: string
  suburb?: string
  postcode?: string
  state?: string
}

export enum ValidationType {
  ShortString = 'ShortString',
  LongString = 'LongString',
  Text = 'Text',
  Number = 'Number',
  Integer = 'Integer',
  BigDecimal = 'BigDecimal',
  Date = 'Date',
  Time = 'Time',
  DateTime = 'DateTime',
  Gender = 'Gender',
  Address = 'Address',
}

export interface RetailProductSupplementaryInfoRequestDto {
  basketId: string | null | undefined
  basketItemId: string | undefined
  retailProductId: string
  items: RetailProductSupplementaryInfo[] | null
}

export interface CostRecoveryCharge {
  revision: Revision | null
  name: string | null
  invoiceNote: string | null
  amountType: AmountType | null
  percentage: number | null
  chargeType: ChargeType | null
  recoveryType: CostRecoveryChargeType | null
  debtor: Debtor | null
  taxable: boolean | null
  fraction: number | null
  fixed: number | null
}

export enum Debtor {
  Consumer = 'Consumer',
  Operator = 'Operator',
  DistributionChannel = 'DistributionChannel',
}

export enum CostRecoveryChargeType {
  Payment = 'Payment',
}

// Remove
export enum BasketErrorCode {
  Expired = 'Expired',
  Unknown = 'Unknown',
}

export interface BasketState {
  parkingBasket: Basket | null
  parkingBasketExpired: boolean
  basketParkingItemIdOnly: BasketItemIdOnly | null
  costRecoveryCharges: CostRecoveryCharge[] | null
}

interface setParkingBasketRequestAction {
  type: typeof SET_PARKING_BASKET_REQUEST
}

interface setParkingBasketSuccessAction {
  type: typeof SET_PARKING_BASKET_SUCCESS
  payload: {
    basket: Basket
  }
}

interface getConfiguredChargesRequestAction {
  type: typeof GET_CONFIGURED_CHARGES_REQUEST
}

interface getConfiguredChargesSuccessAction {
  type: typeof GET_CONFIGURED_CHARGES_SUCCESS
  payload: {
    charges: CostRecoveryCharge[]
  }
}

interface setParkingBasketExpiredAction {
  type: typeof SET_PARKING_BASKET_EXPIRED
}

interface resetBasketContactAction {
  type: typeof RESET_BASKET_CONTACT
}

interface resetBasketAction {
  type: typeof RESET_BASKET
}

export type BasketActionTypes =
  | setParkingBasketRequestAction
  | setParkingBasketSuccessAction
  | getConfiguredChargesRequestAction
  | getConfiguredChargesSuccessAction
  | setParkingBasketExpiredAction
  | resetBasketContactAction
  | resetBasketAction

export const isExpired = (errCode: string) => {
  return errCode === '404'
}
