import axios from 'axios'
import { AppThunk } from '..'
import { HTTP_RESPONSE_CODE_404 } from '../../common/constants'
import { buildAxiosConfig, findConfiguration } from '../../common/utility'
import { ErrorResponse } from '../../config/axios/types'
import { AgreementStatus } from '../my-agreements/types'
import { setNotification } from '../notifications/action'
import { NotificationType } from '../notifications/types'
import {
  getFleetEntitlementDetailsSuccess,
  getFleetEntitlementsRequest,
  getFleetEntitlementsSuccess,
  getFleetEntitlementVehiclesRequest,
  getFleetEntitlementVehiclesSuccess,
} from './actions'
import {
  FleetEntitlement,
  GET_CONSUMER_FLEET_ENTITLEMENTS_API_RESOURCE_PATH,
  GET_FLEET_ENTITLEMENT_BY_ID_API_RESOURCE_PATH,
  GET_FLEET_ENTITLEMENT_VEHICLES_API_RESOURCE_PATH,
} from './types'

export const getFleetEntitlements =
  (consumerId: string): AppThunk<Promise<FleetEntitlement[]>> =>
  async (dispatch, getState) => {
    let fleetEntitlements: FleetEntitlement[] = []
    dispatch(getFleetEntitlementsRequest())
    const config = await buildAxiosConfig(getState())
    const { operatorId, organisationId } = findConfiguration(getState())
    config.params = {
      agreementStatus: AgreementStatus.Valid,
      sortBy: 'StartDate',
      sortDirection: 'asc',
    }
    await axios
      .get(
        GET_CONSUMER_FLEET_ENTITLEMENTS_API_RESOURCE_PATH.replace(':operatorId', operatorId)
          .replace(':distributorId', organisationId)
          .replace(':memberId', consumerId),
        config,
      )
      .then((response) => {
        fleetEntitlements = response.data
        dispatch(getFleetEntitlementsSuccess(response.data))
      })
      .catch((error: ErrorResponse | string) => {
        dispatch(getFleetEntitlementsSuccess([]))
        if (typeof error === 'object') {
          dispatch(setNotification(NotificationType.ERROR, error.message))
        }
      })
    return fleetEntitlements
  }

export const getFleetEntitlementById =
  (consumerId: string, entitlementId: string): AppThunk<any> =>
  async (dispatch, getState) => {
    const config = await buildAxiosConfig(getState())
    const { operatorId, organisationId } = findConfiguration(getState())
    await axios
      .get(
        GET_FLEET_ENTITLEMENT_BY_ID_API_RESOURCE_PATH.replace(':operatorId', operatorId)
          .replace(':distributorId', organisationId)
          .replace(':memberId', consumerId)
          .replace(':entitlementId', entitlementId),
        config,
      )
      .then((response) => {
        dispatch(getFleetEntitlementDetailsSuccess(response.data))
      })
      .catch((error: ErrorResponse | string) => {
        dispatch(getFleetEntitlementDetailsSuccess(null))
        if (typeof error === 'object') {
          if (error.code === HTTP_RESPONSE_CODE_404) {
            dispatch(
              setNotification(
                NotificationType.ERROR,
                'This park is not associated with this account. Please sign-out and try again.',
              ),
            )
          } else {
            dispatch(setNotification(NotificationType.ERROR, error.message))
          }
        }
      })
  }

export const getFleetEntitlementVehicles =
  (consumerId: string, entitlementId: string): AppThunk<any> =>
  async (dispatch, getState) => {
    dispatch(getFleetEntitlementVehiclesRequest())
    const config = await buildAxiosConfig(getState())
    const { operatorId, organisationId } = findConfiguration(getState())
    await axios
      .get(
        GET_FLEET_ENTITLEMENT_VEHICLES_API_RESOURCE_PATH.replace(':operatorId', operatorId)
          .replace(':distributorId', organisationId)
          .replace(':memberId', consumerId)
          .replace(':entitlementId', entitlementId),
        config,
      )
      .then((response) => {
        dispatch(getFleetEntitlementVehiclesSuccess(response.data))
      })
      .catch((error: ErrorResponse | string) => {
        dispatch(getFleetEntitlementVehiclesSuccess([]))
        if (typeof error === 'object') {
          dispatch(setNotification(NotificationType.ERROR, error.message))
        }
      })
  }
