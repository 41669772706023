import axios, { AxiosRequestConfig } from 'axios'
import { SESSION_STORAGE_KEY_BASKET_ID } from '../../common/types'
import { buildAxiosConfig, findConfiguration } from '../../common/utility'
import { BASKET_ID_HEADER, ErrorResponse } from '../../config/axios/types'
import { OrganisationType } from '../auth/types'
import { AppThunk } from '../index'
import { setLoading } from '../loading/reducers'
import { setNotification } from '../notifications/action'
import { NotificationType } from '../notifications/types'
import {
  getConfiguredChargesRequest,
  getConfiguredChargesSuccess,
  setParkingBasketExpired,
  setParkingBasketRequest,
  setParkingBasketSuccess,
} from './actions'
import {
  Basket,
  BasketNotification,
  DELETE_DISTRIBUTOR_PAYMENT_FROM_BASKET_API_RESOURCE_PATH,
  DELETE_PAYMENT_FROM_BASKET_API_RESOURCE_PATH,
  GET_BASKET_API_RESOURCE_PATH,
  GET_BASKET_CONFIGURED_CHARGES_API_RESOURCE_PATH,
  GET_DISTRIBUTOR_BASKET_API_RESOURCE_PATH,
  PUT_CONVENIENCE_NOTIFICATIONS_TO_BASKET_API_RESOURCE_PATH,
  PUT_DISTRIBUTOR_CONVENIENCE_NOTIFICATIONS_TO_BASKET_API_RESOURCE_PATH,
  isExpired,
} from './types'

export const getConfiguredCharges =
  (basketId: string): AppThunk<any> =>
  async (dispatch, getState) => {
    dispatch(getConfiguredChargesRequest())
    const config = await buildAxiosConfig(getState())
    const { operatorId } = findConfiguration(getState())
    config.headers = {
      ...config.headers,
      [BASKET_ID_HEADER]: basketId,
    }
    await axios
      .get(GET_BASKET_CONFIGURED_CHARGES_API_RESOURCE_PATH.replace(':operatorId', operatorId), config)
      .then((response) => {
        dispatch(getConfiguredChargesSuccess(response.data))
      })
      .catch((error: ErrorResponse | string) => {
        if (typeof error === 'object') {
          if (isExpired(error.code)) {
            dispatch(setParkingBasketExpired())
          } else {
            dispatch(setNotification(NotificationType.ERROR, error.message))
          }
        }
      })
  }

export const getParkingBasket =
  (basketId: string): AppThunk<any> =>
  async (dispatch, getState) => {
    dispatch(setParkingBasketRequest())
    const config = await buildAxiosConfig(getState())
    const { operatorId, consumerId, organisationId, organisationType } = findConfiguration(getState())
    config.headers = {
      ...config.headers,
      [BASKET_ID_HEADER]: basketId,
    }
    const resourcePath: string =
      organisationType === OrganisationType.Distributor
        ? GET_DISTRIBUTOR_BASKET_API_RESOURCE_PATH.replace(':operatorId', operatorId)
            .replace(':distributorId', organisationId)
            .replace(':memberId', consumerId)
        : GET_BASKET_API_RESOURCE_PATH.replace(':operatorId', operatorId)
    await axios
      .get(resourcePath, config)
      .then((response) => {
        dispatch(setParkingBasketSuccess(response.data))
      })
      .catch((error: ErrorResponse | string) => {
        if (typeof error === 'object') {
          if (isExpired(error.code)) {
            dispatch(setParkingBasketExpired())
          } else {
            dispatch(setNotification(NotificationType.ERROR, error.message))
          }
        }
      })
  }

export const deletePaymentFromBasket =
  (basketId: string): AppThunk<any> =>
  async (dispatch, getState) => {
    dispatch(setLoading(true))
    dispatch(setParkingBasketRequest())
    const config = await buildAxiosConfig(getState())
    const { operatorId, consumerId, organisationId, organisationType } = findConfiguration(getState())
    config.headers = {
      ...config.headers,
      [BASKET_ID_HEADER]: basketId,
    }
    const resourcePath: string =
      organisationType === OrganisationType.Distributor
        ? DELETE_DISTRIBUTOR_PAYMENT_FROM_BASKET_API_RESOURCE_PATH.replace(':operatorId', operatorId)
            .replace(':distributorId', organisationId)
            .replace(':memberId', consumerId)
        : DELETE_PAYMENT_FROM_BASKET_API_RESOURCE_PATH.replace(':operatorId', operatorId)
    await axios
      .delete(resourcePath, config)
      .then((response) => {
        dispatch(setLoading(false))
        dispatch(setParkingBasketSuccess(response.data))
      })
      .catch((error: ErrorResponse | string) => {
        dispatch(setLoading(false))
        if (typeof error === 'object') {
          if (isExpired(error.code)) {
            dispatch(setParkingBasketExpired())
          } else {
            dispatch(setNotification(NotificationType.ERROR, error.message))
          }
        }
      })
  }

export const putConvenienceNotificationsToBasket =
  (putNotificationsReq: BasketNotification[]): AppThunk<Promise<boolean>> =>
  async (dispatch, getState) => {
    let isPutNotificationsSuccess: boolean = false
    dispatch(setParkingBasketRequest())
    const authConfig = await buildAxiosConfig(getState())
    const { operatorId, consumerId, organisationId, organisationType } = findConfiguration(getState())
    const parkingBasket: Basket | null = getState().basketReducer.parkingBasket
    const axiosConfig: AxiosRequestConfig = {
      baseURL: authConfig.baseURL,
      headers: {
        ...authConfig.headers,
        [BASKET_ID_HEADER]: parkingBasket ? parkingBasket.id : '',
      },
    }
    const resourcePath: string =
      organisationType === OrganisationType.Distributor
        ? PUT_DISTRIBUTOR_CONVENIENCE_NOTIFICATIONS_TO_BASKET_API_RESOURCE_PATH.replace(':operatorId', operatorId)
            .replace(':distributorId', organisationId)
            .replace(':memberId', consumerId)
            .replace(':basketItemId', parkingBasket ? parkingBasket.parkingItem.basketItemId : '')
        : PUT_CONVENIENCE_NOTIFICATIONS_TO_BASKET_API_RESOURCE_PATH.replace(':operatorId', operatorId).replace(
            ':basketItemId',
            parkingBasket ? parkingBasket.parkingItem.basketItemId : '',
          )
    await axios
      .put(resourcePath, putNotificationsReq, axiosConfig)
      .then((response) => {
        dispatch(setParkingBasketSuccess(response.data.basket))
        isPutNotificationsSuccess = true
      })
      .catch((error: ErrorResponse | string) => {
        if (typeof error === 'object') {
          if (isExpired(error.code)) {
            dispatch(setParkingBasketExpired())
          } else {
            dispatch(setNotification(NotificationType.ERROR, error.message))
          }
        }
      })
    return isPutNotificationsSuccess
  }

export const deleteParkingBasket = (): AppThunk<any> => async (_dispatch, getState) => {
  const basketId: string | null = sessionStorage.getItem(SESSION_STORAGE_KEY_BASKET_ID)
  if (!basketId) {
    return
  }
  const config = await buildAxiosConfig(getState())
  const { operatorId, consumerId, organisationId, organisationType } = findConfiguration(getState())
  config.headers = {
    ...config.headers,
    [BASKET_ID_HEADER]: basketId,
  }
  const resourcePath: string =
    organisationType === OrganisationType.Distributor
      ? GET_DISTRIBUTOR_BASKET_API_RESOURCE_PATH.replace(':operatorId', operatorId)
          .replace(':distributorId', organisationId)
          .replace(':memberId', consumerId)
      : GET_BASKET_API_RESOURCE_PATH.replace(':operatorId', operatorId)
  await axios
    .delete(resourcePath, config)
    .then(() => {
      sessionStorage.removeItem(SESSION_STORAGE_KEY_BASKET_ID)
    })
    .catch(() => {})
}
