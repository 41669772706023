import { BASE_DISTRIBUTOR_MEMBER_URL } from '../../config/axios/types'
import { AccessibleReason } from '../operating-hours/types'
import { SalesChannelType } from '../park-and-pay/types'
import {
  AgreementRelationType,
  FacilityRevision,
  FromPrice,
  OfferDto,
  ParkingNotificationQuote,
  PurchaseType,
  QuoteDto,
  QuoteResult,
  QuoteWarning,
  SingleStayDto,
} from '../parking-quote/types'

export const GET_PARKING_QUOTES_AND_EXIT_TIMES_API_PATH = '/public/operators/:operatorId/parking-quotes-exit-times'
export const GET_DISTRIBUTOR_PARKING_QUOTES_AND_EXIT_TIMES_API_PATH = `${BASE_DISTRIBUTOR_MEMBER_URL}/parking-quotes-exit-times`

export const GET_PARKING_QUOTES_AND_EXIT_TIMES_REQUEST = 'GET_PARKING_QUOTES_AND_EXIT_TIMES_REQUEST'
export const GET_PARKING_QUOTES_AND_EXIT_TIMES_SUCCESS = 'GET_PARKING_QUOTES_AND_EXIT_TIMES_SUCCESS'
export const RESET_PARKING_QUOTES_AND_EXIT_TIMES = 'RESET_PARKING_QUOTES_AND_EXIT_TIMES'

export enum ItemListSelectionType {
  Fixed = 'Fixed',
  Variable = 'Variable',
}

export interface ParkingQuotesAndExitTimesReq {
  kind: string
  consumerId?: string
  facilityIds: string[]
  salesChannelType: SalesChannelType
  relatedParkingItem?: RelatedParkingItem
}

export interface RelatedParkingItem {
  relationType: AgreementRelationType
  id: string
}

export interface ExistingParkingQuoteItem {
  startInstant: number
  finishInstant: number
  startDateTime: string
  finishDateTime: string
  durationMinutes: number
}

export interface ParkingQuoteExitTimesItem {
  id: string
  charge: number
  timeZoneId: string
  allowAmend: boolean
  allowCancel: boolean
  allowTimeExtend: boolean
  singleStay: SingleStayDto
}

export interface ParkingQuotesAndExitTimesRes {
  id: string
  kind: string
  quote: QuoteDto
  selfLink: string
  vehicleQuantity: number
  requestedFacilityIds: string[]
  itemList: ParkingQuoteExitTimesItem[]
  itemListSelectionType: ItemListSelectionType
  quoteResult: QuoteResult
  timeStepMinutes: number
  selectorSizeMinutes: number
  existingList: ExistingParkingQuoteItem[]
  accessible: boolean
  accessibleReason?: AccessibleReason
  offer: OfferDto
  notifications: ParkingNotificationQuote[]
  purchaseType: PurchaseType
  maxStayOperationHours: number
  termFromPrice: FromPrice
  facilityRevision: FacilityRevision
  relatedAgreement?: RelatedParkingItem
  warnings?: QuoteWarning[]
}

export interface ParkingQuotesAndExitTimesState {
  parkingQuotesAndExitTimes: ParkingQuotesAndExitTimesRes | null
}

export interface ParkingQuotesAndExitTimesPromiseResponse {
  id: string
  success: boolean
  accessible: boolean
  accessibleReason: AccessibleReason | null
  itemList: ParkingQuoteExitTimesItem[]
  warnings?: QuoteWarning[]
}

interface getParkingQuotesAndExitTimesRequestAction {
  type: typeof GET_PARKING_QUOTES_AND_EXIT_TIMES_REQUEST
}

interface getParkingQuotesAndExitTimesSuccessAction {
  type: typeof GET_PARKING_QUOTES_AND_EXIT_TIMES_SUCCESS
  payload: {
    parkingQuotesAndExitTimes: ParkingQuotesAndExitTimesRes
  }
}

interface resetParkingQuotesAndExitTimesAction {
  type: typeof RESET_PARKING_QUOTES_AND_EXIT_TIMES
}

export type ParkingQuotesAndExitTimesActionTypes =
  | getParkingQuotesAndExitTimesRequestAction
  | getParkingQuotesAndExitTimesSuccessAction
  | resetParkingQuotesAndExitTimesAction
