import axios, { AxiosError } from 'axios'
import { Dispatch } from 'react'
import { OOPS_ERROR_MESSAGE } from '../../common/messages'
import { setNotification } from '../../store/notifications/action'
import { NotificationType } from '../../store/notifications/types'
import { ErrorResponse, ErrorResponseData, SERVER_API_TIMEOUT } from './types'

const setUpAxios = (dispatch: Dispatch<any>) => {
  axios.defaults.timeout = SERVER_API_TIMEOUT

  const onRequestSuccess = (config: any) => {
    console.debug('OnRequestSuccess:', config)
    return config
  }

  const onResponseSuccess = (response: any) => {
    console.debug('onResponseSuccess:', response)
    return response
  }

  const onResponseError = (err: AxiosError) => {
    if (err.response) {
      console.error('onResponseError:', JSON.stringify(err.response))
      const errorResponseData: ErrorResponseData | ErrorResponse[] = err.response.data as
        | ErrorResponseData
        | ErrorResponse[]
      if (Array.isArray(errorResponseData)) {
        if (errorResponseData.length > 0) {
          const errorResponse: ErrorResponse = errorResponseData[0] as ErrorResponse
          return Promise.reject(errorResponse)
        } else {
          dispatch(setNotification(NotificationType.ERROR, OOPS_ERROR_MESSAGE))
          return Promise.reject(OOPS_ERROR_MESSAGE)
        }
      } else if (errorResponseData.errors && errorResponseData.errors.length > 0) {
        const errorResponse: ErrorResponse = errorResponseData.errors[0] as ErrorResponse
        return Promise.reject(errorResponse)
      } else {
        dispatch(setNotification(NotificationType.ERROR, err.response.statusText))
        return Promise.reject(err.response.statusText)
      }
    } else if (err.code || err.message) {
      console.error('onResponseError:', err.code || err.message)
      dispatch(setNotification(NotificationType.ERROR, err.message))
      return Promise.reject(err.message)
    } else {
      console.error('onResponseError:', err.toJSON)
      dispatch(setNotification(NotificationType.ERROR, OOPS_ERROR_MESSAGE))
      return Promise.reject(err.toJSON)
    }
  }

  axios.interceptors.request.use(onRequestSuccess)
  axios.interceptors.response.use(onResponseSuccess, onResponseError)
}

export default setUpAxios
