const MuiTextField = {
  styleOverrides: {
    root: {
      borderRadius: 6,
      fontSize: 16,
      height: 48,
      '& label.Mui-focused': {
        color: '#383838',
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#E2E2E2',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#e53935',
      },
      '& .MuiOutlinedInput-root': {
        color: '#383838',
        '& fieldset': {
          border: '2px solid #dcd9d9',
        },
        '&:hover fieldset': {
          border: '2px solid #dcd9d9',
        },
        '&.Mui-focused fieldset': {
          border: '2px solid #dcd9d9',
        },
        '&.Mui-disabled fieldset': {
          border: '2px solid #dcd9d9',
          color: '#383838',
        },
      },
    },
  },
}

export default MuiTextField
