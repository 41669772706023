import { OrderStatus } from '../../common/types'
import { BASE_DISTRIBUTOR_MEMBER_URL } from '../../config/axios/types'
import { CreditCardType } from '../basket/types'
import { AgreementStatus } from '../my-agreements/types'
import { SalesChannelType, TransactionStatus, TransactionType } from '../park-and-pay/types'

export interface ConsumerTransaction {
  orderId: string
  id: string
  orderStatus: OrderStatus
  orderReference: string
  agreementReference: string
  agreementStatus: AgreementStatus
  orderCreatedDateTime: string
  salesChannelType: SalesChannelType
  cardType: CreditCardType
  cardMasked: string
  transactionType: TransactionType
  publicTransactionType: TransactionType
  transactionStatus: TransactionStatus
  transactionAmount: number
  responseText: string
  responseCode: string
  transactionDateTime: string
}

export interface ConsumerTransactionsState {
  loading: boolean
  consumerTransactions: ConsumerTransaction[]
}

export const GET_CONSUMER_TRANSACTIONS_API_RESOURCE_PATH =
  '/operators/:operatorId/consumers/:consumerId/my-transactions'
export const GET_DISTRIBUTOR_TRANSACTIONS_API_RESOURCE_PATH = `${BASE_DISTRIBUTOR_MEMBER_URL}/my-transactions`

export const GET_CONSUMER_TRANSACTIONS_REQUEST = 'GET_CONSUMER_TRANSACTIONS_REQUEST'
export const GET_CONSUMER_TRANSACTIONS_SUCCESS = 'GET_CONSUMER_TRANSACTIONS_SUCCESS'

export interface getConsumerTransactionsRequest {
  type: typeof GET_CONSUMER_TRANSACTIONS_REQUEST
}

export interface getConsumerTransactionsSuccess {
  type: typeof GET_CONSUMER_TRANSACTIONS_SUCCESS
  payload: {
    data: ConsumerTransaction[]
  }
}

export type ConsumerTransactionsActionTypes = getConsumerTransactionsRequest | getConsumerTransactionsSuccess
