import { Backdrop, CircularProgress } from '@mui/material'
import * as React from 'react'

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 9999,
    alignItems: 'flex-start',
    background: 'transparent',
  },
  spinnerContainer: {
    top: 190,
    position: 'absolute',
  },
  progressSpinner: {
    width: '50px !important',
    height: '50px !important',
    color: 'var(--C_128DC3)',
  },
}))

const Loading: React.FC = () => {
  const classes = useStyles()
  return (
    <Backdrop className={classes.backdrop} open>
      <div className={classes.spinnerContainer}>
        <CircularProgress className={classes.progressSpinner} />
      </div>
    </Backdrop>
  )
}

export default Loading
