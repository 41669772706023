import axios from 'axios'
import { buildAxiosConfig, findConfiguration } from '../../common/utility'
import { ErrorResponse } from '../../config/axios/types'
import { AppThunk } from '../index'
import { setNotification } from '../notifications/action'
import { NotificationType } from '../notifications/types'
import { ConsumerType, Lifecycle } from '../park-and-pay/types'
import {
  getConsumerFullProfileRequest,
  getConsumerFullProfileSuccess,
  saveConsumerRequest,
  saveConsumerSuccess,
  whoAmiRequest,
  whoAmiSuccess,
} from './actions'
import {
  CHECK_CONSUMER_STATUS,
  CREATE_SIGNED_UP_CONSUMER_RESOURCE_PATH,
  ConsumerFullProfile,
  ConsumerSaveRequest,
  EmailStatus,
  FETCH_WHOAMI_RESOURCE_PATH,
  GET_CONSUMER_FULL_PROFILE_RESOURCE_PATH,
  GET_DISTRIBUTOR_MEMBER_FULL_PROFILE_RESOURCE_PATH,
  OrganisationType,
  UPDATE_CONSUMER_LIFECYCLE_API_RESOURCE_PATH,
  UPDATE_DISTRIBUTOR_MEMBER_LIFECYCLE_API_RESOURCE_PATH,
  User,
} from './types'

export const createSignedUpConsumer =
  (consumerSaveRequest: ConsumerSaveRequest): AppThunk<Promise<boolean>> =>
  async (dispatch, getState) => {
    let createSignedUpConsumerSuccess: boolean = false
    dispatch(saveConsumerRequest())
    const { operatorId } = findConfiguration(getState())
    const authConfig = await buildAxiosConfig(getState(), true)
    consumerSaveRequest = {
      ...consumerSaveRequest,
      operatorId: operatorId,
      consumerType: ConsumerType.SignedUp,
    }
    await axios
      .post(CREATE_SIGNED_UP_CONSUMER_RESOURCE_PATH.replace(':operatorId', operatorId), consumerSaveRequest, authConfig)
      .then((response) => {
        dispatch(saveConsumerSuccess(response.data))
        createSignedUpConsumerSuccess = true
      })
      .catch((error: ErrorResponse | string) => {
        if (typeof error === 'object') {
          dispatch(setNotification(NotificationType.ERROR, error.message))
        }
      })
    return createSignedUpConsumerSuccess
  }

export const fetchWhoAmI = (): AppThunk<Promise<User | null>> => async (dispatch, getState) => {
  let currentUser: User | null = null
  dispatch(whoAmiRequest())
  const { tenantId } = findConfiguration(getState())
  const authConfig = await buildAxiosConfig(getState(), true)
  await axios
    .get(FETCH_WHOAMI_RESOURCE_PATH.replace(':tenantId', tenantId), authConfig)
    .then((response) => {
      currentUser = response.data
      dispatch(whoAmiSuccess(response.data))
    })
    .catch((error: ErrorResponse | string) => {
      dispatch(whoAmiSuccess(null))
      if (typeof error === 'object') {
        dispatch(setNotification(NotificationType.ERROR, error.message))
      }
    })
  return currentUser
}

export const getConsumerFullProfile =
  (consumerId: string): AppThunk<Promise<ConsumerFullProfile | null>> =>
  async (dispatch, getState) => {
    let consumerFullProfile: ConsumerFullProfile | null = null
    dispatch(getConsumerFullProfileRequest())
    const authConfig = await buildAxiosConfig(getState(), true)
    const { operatorId, organisationId, organisationType } = findConfiguration(getState())
    const resourcePath: string =
      organisationType === OrganisationType.Distributor
        ? GET_DISTRIBUTOR_MEMBER_FULL_PROFILE_RESOURCE_PATH.replace(':operatorId', operatorId)
            .replace(':distributorId', organisationId)
            .replace(':memberId', consumerId)
        : GET_CONSUMER_FULL_PROFILE_RESOURCE_PATH.replace(':operatorId', operatorId).replace(':consumerId', consumerId)
    await axios
      .get(resourcePath, authConfig)
      .then((response) => {
        consumerFullProfile = response.data
        dispatch(getConsumerFullProfileSuccess(response.data))
      })
      .catch((error: ErrorResponse | string) => {
        dispatch(getConsumerFullProfileSuccess(null))
        if (typeof error === 'object') {
          dispatch(setNotification(NotificationType.ERROR, error.message))
        }
      })
    return consumerFullProfile
  }

export const checkConsumerStatus =
  (consumerEmail: string): AppThunk<Promise<EmailStatus | null>> =>
  async (dispatch, getState) => {
    let consumerStatus: EmailStatus | null = null
    const { operatorId } = findConfiguration(getState())
    const authConfig = await buildAxiosConfig(getState(), true)
    await axios
      .post(CHECK_CONSUMER_STATUS.replace(':operatorId', operatorId), { email: consumerEmail }, authConfig)
      .then((response) => {
        consumerStatus = response.data.status
      })
      .catch((error: ErrorResponse | string) => {
        if (typeof error === 'object') {
          dispatch(setNotification(NotificationType.ERROR, error.message))
        }
      })
    return consumerStatus
  }

export const updateConsumerLifecycle =
  (consumerId: string, lifecycle: Lifecycle): AppThunk<Promise<boolean>> =>
  async (_dispatch, getState) => {
    let responseSuccess: boolean = false
    const authConfig = await buildAxiosConfig(getState())
    const { operatorId, organisationId, organisationType } = findConfiguration(getState())
    const resourcePath: string =
      organisationType === OrganisationType.Distributor
        ? UPDATE_DISTRIBUTOR_MEMBER_LIFECYCLE_API_RESOURCE_PATH.replace(':operatorId', operatorId)
            .replace(':distributorId', organisationId)
            .replace(':memberId', consumerId)
        : UPDATE_CONSUMER_LIFECYCLE_API_RESOURCE_PATH.replace(':operatorId', operatorId).replace(
            ':consumerId',
            consumerId,
          )
    await axios
      .patch(
        resourcePath,
        {
          lifecycle: lifecycle,
          acknowledge: true,
        },
        authConfig,
      )
      .then(() => {
        responseSuccess = true
      })
      .catch(() => {
        responseSuccess = false
      })
    return responseSuccess
  }
