import { AccessMode } from '../../common/types'
import { BASE_DISTRIBUTOR_MEMBER_URL } from '../../config/axios/types'

export interface ConsumerAccessCredential {
  id: string
  consumerId: string
  accessMode: AccessMode
  value: string
  createdDateTime: string
  createdBy: string
  credentialStatus: string
}

export interface UpdateAccessCredentialPromiseResponse {
  success: boolean
  value: string
}

export interface AccessCredentialsState {
  accessCredentials: ConsumerAccessCredential[]
}

export const GET_CONSUMER_ACCESS_CREDENTIALS_API_RESOURCE_PATH =
  '/operators/:operatorId/consumers/:consumerId/access-credential-generations'
export const GET_DISTRIBUTOR_ACCESS_CREDENTIALS_API_RESOURCE_PATH = `${BASE_DISTRIBUTOR_MEMBER_URL}/access-credential-generations`
export const UPDATE_CONSUMER_ACCESS_CREDENTIALS_API_RESOURCE_PATH =
  '/operators/:operatorId/consumers/:consumerId:regenerateQR'
export const UPDATE_DISTRIBUTOR_ACCESS_CREDENTIALS_API_RESOURCE_PATH = `${BASE_DISTRIBUTOR_MEMBER_URL}:regenerateQR`

export const GET_CONSUMER_ACCESS_CREDENTIALS_REQUEST = 'GET_CONSUMER_ACCESS_CREDENTIALS_REQUEST'
export const GET_CONSUMER_ACCESS_CREDENTIALS_SUCCESS = 'GET_CONSUMER_ACCESS_CREDENTIALS_SUCCESS'

export interface getConsumerAccessCredentialsRequest {
  type: typeof GET_CONSUMER_ACCESS_CREDENTIALS_REQUEST
}

export interface getConsumerAccessCredentialsSuccess {
  type: typeof GET_CONSUMER_ACCESS_CREDENTIALS_SUCCESS
  payload: {
    data: ConsumerAccessCredential[]
  }
}

export type AccessCredentialsActionTypes = getConsumerAccessCredentialsRequest | getConsumerAccessCredentialsSuccess
