import {
  FleetEntitlement,
  FleetEntitlementDetails,
  FleetEntitlementsActionTypes,
  FleetEntitlementVehicle,
  GET_FLEET_ENTITLEMENT_DETAILS_SUCCESS,
  GET_FLEET_ENTITLEMENT_VEHICLES_REQUEST,
  GET_FLEET_ENTITLEMENT_VEHICLES_SUCCESS,
  GET_FLEET_ENTITLEMENTS_REQUEST,
  GET_FLEET_ENTITLEMENTS_SUCCESS,
  RESET_FLEET_ENTITLEMENT_DETAILS,
  RESET_FLEET_ENTITLEMENTS,
} from './types'

export function getFleetEntitlementsRequest(): FleetEntitlementsActionTypes {
  return {
    type: GET_FLEET_ENTITLEMENTS_REQUEST,
  }
}

export function getFleetEntitlementsSuccess(fleetEntitlements: FleetEntitlement[]): FleetEntitlementsActionTypes {
  return {
    type: GET_FLEET_ENTITLEMENTS_SUCCESS,
    payload: {
      data: fleetEntitlements,
    },
  }
}

export function getFleetEntitlementDetailsSuccess(
  fleetEntitlementDetails: FleetEntitlementDetails | null,
): FleetEntitlementsActionTypes {
  return {
    type: GET_FLEET_ENTITLEMENT_DETAILS_SUCCESS,
    payload: {
      data: fleetEntitlementDetails,
    },
  }
}

export function getFleetEntitlementVehiclesRequest(): FleetEntitlementsActionTypes {
  return {
    type: GET_FLEET_ENTITLEMENT_VEHICLES_REQUEST,
  }
}

export function getFleetEntitlementVehiclesSuccess(
  fleetEntitlementVehicles: FleetEntitlementVehicle[],
): FleetEntitlementsActionTypes {
  return {
    type: GET_FLEET_ENTITLEMENT_VEHICLES_SUCCESS,
    payload: {
      data: fleetEntitlementVehicles,
    },
  }
}

export function resetFleetEntitlements(): FleetEntitlementsActionTypes {
  return {
    type: RESET_FLEET_ENTITLEMENTS,
  }
}

export function resetFleetEntitlementDetails(): FleetEntitlementsActionTypes {
  return {
    type: RESET_FLEET_ENTITLEMENT_DETAILS,
  }
}
