import { BASE_DISTRIBUTOR_MEMBER_URL } from '../../config/axios/types'
import { FacilityInfo, InactiveOrderAgreement, LinkSource } from '../my-agreements/types'
import { OrderFinancialTransaction, OrderMaskedContactDto, ParkingLineItem } from '../park-and-pay/types'

export interface RecentParksState {
  recentParks: InactiveOrderAgreement[]
}

export interface RecentParkDetailsPromise {
  contact: OrderMaskedContactDto | null
  facility: FacilityInfo | null
  financialTransactions: OrderFinancialTransaction[]
  linkSource: LinkSource | null
  parkingLineItem: ParkingLineItem | null
  promoCode?: string
  refundAmount: number
  success: boolean
}

export const GET_CONSUMER_RECENT_PARKS_API_RESOURCE_PATH = '/operators/:operatorId/consumers/:consumerId/my-agreements'
export const GET_DISTRIBUTOR_RECENT_PARKS_API_RESOURCE_PATH = `${BASE_DISTRIBUTOR_MEMBER_URL}/my-agreements`
export const GET_CONSUMER_RECENT_PARK_DETAILS_API_RESOURCE_PATH =
  '/operators/:operatorId/consumers/:consumerId/my-orders/:recentParkOrderId'
export const GET_DISTRIBUTOR_RECENT_PARK_DETAILS_API_RESOURCE_PATH = `${BASE_DISTRIBUTOR_MEMBER_URL}/my-orders/:recentParkOrderId`

export const GET_CONSUMER_RECENT_PARKS_REQUEST = 'GET_CONSUMER_RECENT_PARKS_REQUEST'
export const GET_CONSUMER_RECENT_PARKS_SUCCESS = 'GET_CONSUMER_RECENT_PARKS_SUCCESS'

export interface getConsumerRecentParksRequest {
  type: typeof GET_CONSUMER_RECENT_PARKS_REQUEST
}

export interface getConsumerRecentParksSuccess {
  type: typeof GET_CONSUMER_RECENT_PARKS_SUCCESS
  payload: {
    data: InactiveOrderAgreement[]
  }
}

export type RecentParksActionTypes = getConsumerRecentParksRequest | getConsumerRecentParksSuccess
