import { AccessMode, UserAgentType } from '../../common/types'
import { BASE_DISTRIBUTOR_MEMBER_URL } from '../../config/axios/types'

export interface AccessPointState {
  consumerAccessError: boolean
  casualAccessError: boolean
  consumerAccess: ConsumerAccess | null
  casualAccess: CasualAccess | null
}

export enum GateDeliveryStatus {
  Pending = 'Pending',
  Success = 'Success',
  Failed = 'Failed',
}

export enum AccessResultStatus {
  Unknown = 'Unknown',
  Approved = 'Approved',
  Declined = 'Declined',
  GateOffline = 'GateOffline',
}

export enum AccessType {
  Entry = 'Entry',
  Exit = 'Exit',
  Dual = 'Dual',
}

export interface AccessResult {
  status: AccessResultStatus
  code: string
  text: string
}

export interface ConsumerAccess {
  accessRequestId: string
  gateDeliveryStatus: GateDeliveryStatus
  accessResult: AccessResult
}

export interface CasualAccess {
  accessRequestId: string
  agreementId: string
  accessResult: AccessResult
}

export interface ConfirmAccessPointRequest {
  agreementId: string
  accessPointId?: string
  accessPointPublicId?: string
  directAccessRequestMode: AccessMode
}

export interface CasualAccessPointRequest {
  accessPointId?: string
  accessPointPublicId?: string
  accessRequestMode: AccessMode
  existingAgreementId?: string
  userAgentType: UserAgentType
  referer: string
}

export const CONFIRM_PUBLIC_ACCESS_POINT_BY_CLIENT_REF_API_RESOURCE_PATH =
  '/public/operators/:operatorId/facilities-by-clientref/:clientRef/consumer-requested-accesses'
export const CONFIRM_CONSUMER_ACCESS_POINT_BY_CLIENT_REF_API_RESOURCE_PATH =
  '/operators/:operatorId/consumers/:consumerId/facilities-by-clientref/:clientRef/consumer-requested-accesses'
export const CONFIRM_DISTRIBUTOR_MEMBER_ACCESS_POINT_BY_CLIENT_REF_API_RESOURCE_PATH = `${BASE_DISTRIBUTOR_MEMBER_URL}/facilities-by-clientref/:clientRef/consumer-requested-accesses`

export const CASUAL_ACCESS_POINT_BY_CLIENT_REF_API_RESOURCE_PATH =
  '/public/operators/:operatorId/facilities-by-clientref/:clientRef/casual-consumer-accesses'
export const CONSUMER_CASUAL_ACCESS_POINT_BY_CLIENT_REF_API_RESOURCE_PATH =
  '/operators/:operatorId/consumers/:consumerId/facilities-by-clientref/:clientRef/casual-consumer-accesses'
export const DISTRIBUTOR_CASUAL_ACCESS_POINT_BY_CLIENT_REF_API_RESOURCE_PATH = `${BASE_DISTRIBUTOR_MEMBER_URL}/facilities-by-clientref/:clientRef/casual-consumer-accesses`

export const POST_ACCESS_POINT_REQUEST = 'POST_ACCESS_POINT_REQUEST'
export const POST_ACCESS_POINT_SUCCESS = 'POST_ACCESS_POINT_SUCCESS'
export const POST_ACCESS_POINT_FAILURE = 'POST_ACCESS_POINT_FAILURE'
export const POST_CASUAL_ACCESS_POINT_REQUEST = 'POST_CASUAL_ACCESS_POINT_REQUEST'
export const POST_CASUAL_ACCESS_POINT_SUCCESS = 'POST_CASUAL_ACCESS_POINT_SUCCESS'
export const POST_CASUAL_ACCESS_POINT_FAILURE = 'POST_CASUAL_ACCESS_POINT_FAILURE'
export const RESET_ACCESS_POINT_DELIVERY_STATUS = 'RESET_ACCESS_POINT_DELIVERY_STATUS'

interface postAccessPointRequestAction {
  type: typeof POST_ACCESS_POINT_REQUEST
}
interface postAccessPointSuccessAction {
  type: typeof POST_ACCESS_POINT_SUCCESS
  payload: {
    consumerAccess: ConsumerAccess
  }
}
interface postAccessPointFailureAction {
  type: typeof POST_ACCESS_POINT_FAILURE
}

interface postCasualAccessPointRequestAction {
  type: typeof POST_CASUAL_ACCESS_POINT_REQUEST
}
interface postCasualAccessPointSuccessAction {
  type: typeof POST_CASUAL_ACCESS_POINT_SUCCESS
  payload: {
    data: CasualAccess
  }
}
interface postCasualAccessPointFailureAction {
  type: typeof POST_CASUAL_ACCESS_POINT_FAILURE
}

interface resetAccessPointDeliveryStatusAction {
  type: typeof RESET_ACCESS_POINT_DELIVERY_STATUS
}

export type AccessPointActionTypes =
  | postAccessPointRequestAction
  | postAccessPointSuccessAction
  | postAccessPointFailureAction
  | postCasualAccessPointRequestAction
  | postCasualAccessPointSuccessAction
  | postCasualAccessPointFailureAction
  | resetAccessPointDeliveryStatusAction
