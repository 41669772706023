// Auth
export const ROUTE_PATH_SIGN_IN = '/sign-in'
export const ROUTE_PATH_FORGOT_PASSWORD = '/forgot-password'
export const ROUTE_PATH_SIGN_UP = '/sign-up'
export const ROUTE_PATH_VERIFY_EMAIL = '/verify-email'
export const ROUTE_PATH_VERIFY_MOBILE = '/account-management'
export const ROUTE_PATH_VERIFY_CONSUMER_MOBILE = '/verify-mobile'
export const ROUTE_PATH_VERIFICATION_FAILURE = '/verification-failure'
export const ROUTE_PATH_VERIFICATION_INVALID = '/verification-invalid'
export const ROUTE_PATH_RESET_PASSWORD = '/reset-password'
export const ROUTE_PATH_VERIFICATION_COMPLETE = '/verification-complete'
export const ROUTE_PATH_VERIFICATION_INCOMPLETE = '/verification-incomplete'
export const ROUTE_PATH_ACCOUNT_EXISTS_ERROR = '/account-exists-error'
export const ROUTE_PATH_SESSION_TERMINATED = '/session-terminated'
export const ROUTE_PATH_ACCOUNT_SUSPENDED = '/account-suspended'
// Auth

// Home
export const ROUTE_PATH_HOME = '/home'
export const ROUTE_PATH_APP_VERSION = '/app-version'
// Home

// PWA
export const ROUTE_PATH_ALLOW_COOKIE = '/'
export const ROUTE_PATH_INSTALL_APP = '/install-app'
export const ROUTE_PATH_INSTALL_APP_LOADING = '/install-app-loading'
export const ROUTE_PATH_INSTALL_APP_SUCCESS = '/Install-app-success'
export const ROUTE_PATH_INSTALL_APP_EXIST = '/Install-app-exist'
export const ROUTE_PATH_INSTALL_APP_IOS = '/Install-app-ios'
// PWA

//Res Flow
export const ROUTE_PATH_SALES_CHANNEL_RES = '/Res'
export const ROUTE_PATH_SEARCH_CAR_PARK = '/Res/parking-search'
export const ROUTE_PATH_SEARCH_CARPARK = '/search-carpark'
export const ROUTE_PATH_SELECT_DURATION = '/Res/select-duration'
export const ROUTE_PATH_PARKING_OFFER = '/Res/parking-offer'
export const ROUTE_PATH_PARKING_REMINDERS = '/Res/parking-reminders'
export const ROUTE_PATH_CONTACT_DETAILS = '/Res/contact-details'
export const ROUTE_PATH_VEHICLE_DETAILS = '/Res/vehicle-details'
export const ROUTE_PATH_VEHICLE_DETAILS_GUEST = '/Res/vehicle-details-guest'
export const ROUTE_PATH_PAYMENT_DETAILS = '/Res/payment-details'
export const ROUTE_PATH_PAYMENT_DETAILS_GUEST = '/Res/payment-details-guest'
export const ROUTE_PATH_PAYMENT_DECLINED = '/Res/payment-declined'
export const ROUTE_PATH_ORDER_CONFIRMATION = '/Res/order-confirmation'
export const ROUTE_PATH_ORDER_PROCESSING = '/Res/order-processing'
export const ROUTE_PATH_ORDER_COMPLETION = '/Res/order-completion'
export const ROUTE_PATH_WEB_API_LINK = '/Res/link'
export const ROUTE_PATH_DUPLICATE_BOOKING_WARNING = '/Res/duplicate-booking-warning'
export const ROUTE_PATH_SELECT_CARPARK_BY_OFFER = '/select-carpark-by-offer'
export const ROUTE_PATH_INVALID_OPERATION_REQUEST = '/invalid-operation-request'
//Res Flow

// Park Flow
export const ROUTE_PATH_SALES_CHANNEL_PARK = '/Park'
export const ROUTE_PATH_PARK_LOCATION_DETAILS = '/Park/park-location-details'
export const ROUTE_PATH_PARK_PARKING_LOCATION = '/Park/park-parking-location'
export const ROUTE_PATH_PARK_SELECT_DURATION = '/Park/park-select-duration'
export const ROUTE_PATH_PARK_SELECT_DURATION_CLIENT_REF = '/Park/link'
export const ROUTE_PATH_PARK_VEHICLES = '/Park/park-vehicles'
export const ROUTE_PATH_PARK_CARDS = '/Park/park-cards'
export const ROUTE_PATH_PARK_YOUR_DETAILS = '/Park/park-your-details'
export const ROUTE_PATH_PARK_GUEST_YOUR_DETAILS = '/Park/park-guest-your-details'
export const ROUTE_PATH_PARK_GUEST_PAYMENT_DETAILS = '/Park/park-guest-payment-details'
export const ROUTE_PATH_PARK_GUEST_EDIT_DETAILS = '/Park/park-guest-edit-details'
export const ROUTE_PATH_PARK_CONFIRM_ORDER = '/Park/park-confirm-order'
export const ROUTE_PATH_PARK_CONFIRM_PAYMENT = '/Park/park-confirm-payment'
export const ROUTE_PATH_PARK_PROCESSING_ORDER = '/Park/park-processing-order'
export const ROUTE_PATH_PARK_PROCESSING_PAYMENT = '/Park/park-processing-payment'
export const ROUTE_PATH_PARK_PAYMENT_DECLINED = '/Park/park-payment-declined'
export const ROUTE_PATH_PARK_PAYMENT_APPROVED = '/Park/park-payment-approved'
export const ROUTE_PATH_PARK_FREE_PARKING = '/Park/park-free-parking'
export const ROUTE_PATH_PARK_RESTRICTED_PARKING = '/Park/park-restricted-parking'
export const ROUTE_PATH_PARK_OVERSTAY_WARNING = '/Park/park-overstay-warning'
export const ROUTE_PATH_PARK_DUPLICATE_WARNING = '/Park/park-duplicate-warning'
export const ROUTE_PATH_PARK_PARKING_PERMIT = '/Park/park-parking-permit'
// Park Flow

// Permit
export const ROUTE_PATH_PERMIT_WEB_API_LINK = '/Permit/link'

// CPMS
export const ROUTE_PATH_CPMS_PARKING_SUMMARY = '/cpms-parking-summary'

// My account
export const ROUTE_PATH_MY_ACCOUNT = '/my-account'
export const ROUTE_PATH_MY_PROFILE = ROUTE_PATH_MY_ACCOUNT + '/profile'
export const ROUTE_PATH_MY_VEHICLES = ROUTE_PATH_MY_ACCOUNT + '/vehicles'
export const ROUTE_PATH_MY_CARDS = ROUTE_PATH_MY_ACCOUNT + '/cards'
export const ROUTE_PATH_MY_TRANSACTIONS = ROUTE_PATH_MY_ACCOUNT + '/transactions'
export const ROUTE_PATH_MY_CARPARKS = ROUTE_PATH_MY_ACCOUNT + '/carparks'
export const ROUTE_PATH_MY_CHARGE_ACCOUNT = ROUTE_PATH_MY_ACCOUNT + '/charge-account'
export const ROUTE_PATH_SUBSCRIPTIONS = ROUTE_PATH_MY_ACCOUNT + '/subscriptions'
export const ROUTE_PATH_SETTINGS = ROUTE_PATH_MY_ACCOUNT + '/settings'
export const ROUTE_PATH_SECURITY = ROUTE_PATH_MY_ACCOUNT + '/security'
export const ROUTE_PATH_FORGET_ME = ROUTE_PATH_MY_ACCOUNT + '/forget-me'
export const ROUTE_PATH_MANAGE_ACCESS_ID = ROUTE_PATH_MY_ACCOUNT + '/manage-access-id'
export const ROUTE_PATH_MANAGE_ACCESS_RESULT = ROUTE_PATH_MY_ACCOUNT + '/manage-access-result'
// My account

// My activity
export const ROUTE_PATH_MY_ACTIVITY = '/my-activity'
export const ROUTE_PATH_MY_ACTIVITY_HISTORY = ROUTE_PATH_MY_ACTIVITY + '/history'
export const ROUTE_PATH_ENTITLEMENT_VEHICLE = ROUTE_PATH_MY_ACTIVITY + '/entitlement-vehicles'
export const ROUTE_PATH_ENTITLEMENT_DETAILS = '/entitlement-details'
export const ROUTE_PATH_FLEET_ENTITLEMENT_DETAILS = '/fleet-entitlement-details'
export const ROUTE_PATH_FLEET_ENTITLEMENT_VEHICLES = '/fleet-entitlement-vehicles'
export const ROUTE_PATH_VIEW_GUEST_PASS = '/view-guest-pass'
export const ROUTE_PATH_VIEW_EXIT_PASS = '/view-exit-pass'
export const ROUTE_PATH_INVALID_PASS = '/invalid-pass'
// My activity

// Charge Acc flow
export const ROUTE_PATH_CHARGE_ACC_VEHICLES = ROUTE_PATH_MY_CHARGE_ACCOUNT + '/vehicles'
export const ROUTE_PATH_CHARGE_ACC_CARDS = ROUTE_PATH_MY_CHARGE_ACCOUNT + '/cards'
export const ROUTE_PATH_CHARGE_ACC_CONFIRMATION = ROUTE_PATH_MY_CHARGE_ACCOUNT + '/confirmation'
export const ROUTE_PATH_CHARGE_ACC_COMPLETION = ROUTE_PATH_MY_CHARGE_ACCOUNT + '/completion'
// Charge Acc flow

export const ROUTE_PATH_ENTER_CARPARK = '/enter-carpark'
export const ROUTE_PATH_ORDER_DETAILS = '/order-details'
export const ROUTE_PATH_SIGN_IN_WARNING = '/sign-in-warning'
export const ROUTE_PATH_FIND_ACTIVE_PARK = '/find-active-park'
export const ROUTE_PATH_GUEST_AGREEMENTS = '/guest-agreements'
export const ROUTE_PATH_LINK_ORDER_WARNING = '/link-order-warning'
export const ROUTE_PATH_LINK_ORDER_RESPONSE = '/link-order-response'
export const ROUTE_PATH_GUEST_ACTIVE_PARKS = '/guest-active-parks'
export const ROUTE_PATH_FIND_BOOKING_RESPONSE = '/find-booking-response'
export const ROUTE_PATH_EXISTING_BOOKING_WARNING = '/existing-booking-warning'

// Quick Park
export const ROUTE_PATH_QUICK_PARK = '/quick-park'
export const ROUTE_PATH_QUICK_PARK_GUEST = '/quick-park-guest'
export const ROUTE_PATH_QUICK_PARK_SELECT_DURATION = '/quick-park-select-duration'
export const ROUTE_PATH_QUICK_PARK_PARKING_LOCATION = '/quick-park-parking-location'
// Quick Park

// Feedback
export const ROUTE_PATH_FEEDBACK = '/feedback'
export const ROUTE_PATH_FEEDBACK_SUCCESS = '/feedback-success'
// Feedback

// Error Screens
export const ROUTE_PATH_CARPARK_ENTRY_CLOSED_ERROR = '/carpark-entry-closed-error'
// Error Screens

export const VIEW_DETAILS_OF_PARKING_ORDER = 'View details of my park'
export const NEED_HELP_FOR_ACCESSING_CARPARK = 'I need help accessing the carpark'

// HTTP response status codes
export const HTTP_RESPONSE_CODE_200 = 200 // OK
export const HTTP_RESPONSE_CODE_400 = '400' // Bad Request
export const HTTP_RESPONSE_CODE_404 = '404' // Not Found
export const HTTP_RESPONSE_CODE_403 = '403' // Forbidden

// Icon
export const DEFAULT_TILE_BACKGROUND_COLOR = '#4AA8ED'
export const DEFAULT_TILE_ICON_COLOR = '#128DC3'
export const DEFAULT_TILE_TEXT_COLOR = '#FFFFFF'
