import axios from 'axios'
import { AppThunk } from '..'
import { buildAxiosConfig, findConfiguration } from '../../common/utility'
import { ErrorResponse } from '../../config/axios/types'
import { OrganisationType } from '../auth/types'
import { setNotification } from '../notifications/action'
import { NotificationType } from '../notifications/types'
import { getConsumerOrderedFacilitiesRequest, getConsumerOrderedFacilitiesSuccess } from './actions'
import {
  GET_CONSUMER_ORDERED_FACILITIES_API_RESOURCE_PATH,
  GET_DISTRIBUTOR_MEMBER_ORDERED_FACILITIES_API_RESOURCE_PATH,
} from './types'

export const getConsumerOrderedFacilities =
  (consumerId: string): AppThunk<any> =>
  async (dispatch, getState) => {
    dispatch(getConsumerOrderedFacilitiesRequest())
    const authConfig = await buildAxiosConfig(getState(), true)
    const { operatorId, organisationId, organisationType } = findConfiguration(getState())
    const resourcePath: string =
      organisationType === OrganisationType.Distributor
        ? GET_DISTRIBUTOR_MEMBER_ORDERED_FACILITIES_API_RESOURCE_PATH.replace(':operatorId', operatorId)
            .replace(':distributorId', organisationId)
            .replace(':memberId', consumerId)
        : GET_CONSUMER_ORDERED_FACILITIES_API_RESOURCE_PATH.replace(':operatorId', operatorId).replace(
            ':consumerId',
            consumerId,
          )
    await axios
      .get(resourcePath, authConfig)
      .then((response) => {
        dispatch(getConsumerOrderedFacilitiesSuccess(response.data))
      })
      .catch((error: ErrorResponse | string) => {
        if (typeof error === 'object') {
          dispatch(setNotification(NotificationType.ERROR, error.message))
        }
      })
  }
